/* Overflow  */
.of-v {
  overflow: visible;
}

.of-h {
  overflow: hidden;
}

.of-s {
  overflow: scroll;
}

.of-a {
  overflow: auto;
}

.of-x-s {
  overflow-x: scroll;
}

.of-x-h {
  overflow-x: hidden;
}

.of-x-v {
  overflow-x: visible;
}

.of-x-a {
  overflow-x: auto;
}

.of-y-s {
  overflow-y: scroll;
}

.of-y-h {
  overflow-y: hidden;
}

.of-y-v {
  overflow-y: visible;
}

.of-y-a {
  overflow-y: auto;
}
