// ie only mq
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  // this resets a rule in the Tp that sets the card body height to 100% in IE, which breaks the layout in the card slider.
  .cdc-card-slider {
    .card .card-body {
      height: auto !important;
    }
  }
}

.cdc-card-slider {
  margin-bottom: 0;
  display: none;

  // normalize the card heights
  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
    }
  }

  &.slick-initialized {
    display: block;
    animation: fadein 1s;
  }

  .video-transcript a {
    color: #075290 !important;
    margin-right: 0.5rem;

    span {
      margin-left: 0 !important;

      &.cdc-icon-video_01 {
        color: #000 !important;
      }
    }
  }

  ul.slick-dots {
    bottom: -20px;

    &.slick-dots-left {
      text-align: left;
    }

    &.slick-dots-right {
      text-align: right;
    }

    &.slick-dots-outline {
      bottom: 34px !important;
      padding-bottom: 10px;
      background: rgba(0, 0, 0, 0.5);

      li {
        button {
          border: 1px solid #f0f0f0;
          width: 100%;
          height: 10px;
          padding: 5px;
        }

        &.slick-active {
          button {
            background-color: #e0e0e0;
          }
        }
      }
    }

    &.slick-dots-numbers {
      padding: 2px 0;

      li {
        button {
          line-height: initial;
          font-size: 1rem;
          color: #fff;
          height: 30px;

          &:hover {
            color: #000;
          }
        }

        &.slick-active {
          button {
            color: #000;

            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }

  .card {
    margin: 10px;

    .card-img-wrap {
      position: relative;
      overflow: hidden;
    }

    .playbtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
      width: 80px;
      height: 80px;
      cursor: pointer;
    }

    &.dark {
      background-color: #333 !important;
      color: #fff;
      overflow: hidden;
    }

    .card-img-top {
      max-width: 100%;

      // TODO: revisit this, source not likely from youtube..
      &[src*='ytimg.com'] {
        cursor: pointer;
      }
    }

    .card-footer {
      img {
        width: 16px;
        max-width: 16px;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  &.video-modal {
    .card {
      .playbtn {
        width: 50px;
        height: 50px;
      }
    }
  }

  .slick-slide {
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    top: calc(50% - 40px);
    z-index: 5555;
    width: 45px;
    height: 45px;
    display: block !important;
    animation: fadein 0.75s;
  }

  &.cdc-standard-slider {
    .slider-next {
      right: -20px !important;
    }

    .slider-prev {
      left: -20px !important;
    }
  }

  &.cdc-carousel-slider {
    .slider-next {
      right: -20px !important;
    }

    .slider-prev {
      left: -20px !important;
    }
  }

  &.cdc-carousel-thumbnail-slider {
    .slider-next {
      right: -20px !important;
    }

    .slider-prev {
      left: -20px !important;
    }
  }

  &.cdc-thumbnail-slider {
    .slider-next {
      right: -20px !important;
    }

    .slider-prev {
      left: -20px !important;
    }
  }

  .slider-next {
    right: -20px !important;
  }

  .slider-prev {
    left: -20px !important;
  }

  + .cdc-slider-status {
    text-align: center !important;
  }

  // customizations to each slider type appears below
  &.cdc-carousel-thumbnail-slider {
    margin-right: 4px;
    margin-left: 2px;

    .card-img-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      cursor: pointer;
    }

    ul.slick-dots {
      bottom: -35px;
    }

    .slider-next,
    .slider-prev {
      top: calc(50% - 20px);
    }

    + .cdc-slider-status {
      display: none !important;
    }

    .playbtn {
      width: 45px;
      height: 45px;
    }
  }

  &.cdc-carousel-slider {
    .card {
      margin: 5px;
    }

    .slider-next,
    .slider-prev {
      top: calc(50% - 20px);
    }

    .slick-slide {
      &.slick-active {
        box-shadow: none;
      }
    }

    &.d-none {
      + .cdc-slider-status {
        display: none;
      }
    }
  }

  &.cdc-carousel-slider-nav {
    .slick-slide {
      cursor: pointer;
    }
  }

  &.cdc-thumbnail-slider {
    .slider-prev,
    .slider-next {
      top: calc(50% - 20px);
      display: block !important;
    }
  }

  &.cdc-jumbotron-slider {
    // coming soon!
  }

  &.cdc-centermode-slider {
    .slider-next {
      right: -25px !important;
    }

    .slider-prev {
      left: -25px !important;
    }

    .slick-slide {
      opacity: 0.5;

      &:hover {
        opacity: 0.5;
      }
    }

    .slick-slide.slick-active {
      opacity: 1;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    }
  }

  &.cdc-standard-slider-overlay {
    .card-body {
      background: rgba(255, 255, 255, 0.85);
      color: #000;
    }

    &.dark {
      .card {
        overflow: hidden;
      }
      .card-body {
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
      }
    }
  }
}

// video modal related
body.modal-open {
  .cdc-card-slider {
    // hiding next/previous on modal open
    .slider-next,
    .slider-prev {
      display: none !important;
      animation: fadeout 1s;
    }
  }

  // resizing the large modal for the video player
  #CDC_videoModal {
    .close {
      opacity: 0.75;
    }

    .modal-body {
      padding: 0;
    }

    .modal-lg {
      width: 60vw;
      max-width: 60vw;
    }

    .video-transcript a {
      display: inline-block;
      color: #075290 !important;
      margin-right: 0.5rem;

      span {
        margin-left: 0 !important;

        &.cdc-icon-video_01 {
          color: #000 !important;
        }
      }
    }
  }
}

// small VPs only
@media (max-width: 576px) {
  .cdc-card-slider {
    .card {
      // reduce the margin
      margin: 3px;

      // reduce the play button a little bit more
      .playbtn {
        width: 45px;
        height: 45px;
      }

      // stack the anchors in the footer
      .card-footer {
        a {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 766px) {
  //.cdc-card-slider .slick-arrow,
  .cdc-card-slider .slick-dots {
    display: none !important;
  }
}

// everything smaller than medium
@media (max-width: 768px) {
  // if there's a play button, set it's size
  .cdc-card-slider .card {
    margin: 5px;

    .playbtn {
      width: 50px;
      height: 50px;
    }
  }

  // remove the bottom margin on the standard slider
  .cdc-card-slider.slick-dotted {
    margin-bottom: 0;
  }

  // hide everything but the card-title inside the card-body
  .cdc-card-slider.cdc-no-mobile-caption {
    .card-body *:not(.card-title) {
      display: none;
    }
  }
}

// in everything up to large VPs
@media (max-width: 992px) {
  body.modal-open {
    // modal is full width in mobile
    #CDC_videoModal {
      .modal-lg {
        width: 100vw;
        max-width: 100vw;
        margin: 0;
      }
    }
  }

  .cdc-carousel-slider {
    + .cdc-slider-status {
      display: block !important;
    }
  }

  .cdc-card-slider {
    // &.cdc-standard-slider {
    // 	.slick-arrow {
    // 		display: block!important;
    // 	}
    // }
  }
}

// in medium VPs and larger
@media (min-width: 767px) {
  .cdc-card-slider {
    + .cdc-slider-status:not(.d-block) {
      display: none !important;
    }
  }
  .cdc-card-slider {
    &.cdc-carousel-slider {
      + .cdc-slider-status:not(.d-block) {
        display: block !important;
      }
    }
  }
}

// in large VPs and larger
@media (min-width: 992px) {
  // don't show the arrows on the top carousel slider
  .cdc-carousel-slider {
    .slick-arrow {
      display: none !important;
    }
  }

  .cdc-card-slider {
    &.cdc-carousel-slider {
      + .cdc-slider-status:not(.d-block) {
        display: none !important;
      }
    }
  }

  // .cdc-card-slider+.cdc-slider-status:not(.d-block) {
  // 	display: none !important;
  // }

  // position the overlay, using the card body
  .cdc-card-slider {
    &.cdc-standard-slider-overlay {
      position: relative;

      .card-body {
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      &.dark {
        .card {
          overflow: hidden;
        }
        .card-body {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
