@media (max-width: 767.98px) {
  .step-table {
    .row {
      [class*='col-']:not(:last-child) {
        border-bottom: none;
      }

      div > p:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.step-table {
  .row {
    div > p:last-child {
      margin-bottom: 0 !important;
    }
  }

  .column-header {
    margin: 0 !important;
    font-size: 1.125rem;
    font-weight: bold;
  }

  .row-header {
    margin: 0 !important;
    font-size: 1rem;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .step-table {
    .column-header {
      font-size: 1.125rem;
      background-color: inherit !important;
    }

    .row-header {
      font-size: 1.375rem;
      background-color: inherit !important;
    }
  }
}
