/* line-height */
.lh1 {
  line-height: 1;
}

.lh1-1 {
  line-height: 1.1;
}

.lh1-2 {
  line-height: 1.2;
}

.lh1-3 {
  line-height: 1.3;
}

.lh1-4 {
  line-height: 1.4;
}

.lh1-5 {
  line-height: 1.5;
}

.lh1-6 {
  line-height: 1.6;
}

.lh1-7 {
  line-height: 1.7;
}

.lh1-8 {
  line-height: 1.8;
}

.lh1-9 {
  line-height: 1.9;
}

.lh2 {
  line-height: 2;
}
