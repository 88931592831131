// standard colors
$white: #fff !default;
$black: #000 !default;
$red: #8f0000 !default;
$green: #80ff80 !default;
$yellow: #ffff80 !default;
$orange: orange !default;

$cdcblue: #075290;

// theme colors
$primary: #005eaa !default;
$secondary: #88c3ea !default;
$tertiary: #c0e9ff !default;
$quaternary: #edf9ff !default;

$purple-primary: #712177 !default;
$purple-secondary: #b890bb !default;
$purple-tertiary: #e3d3e4 !default;
$purple-quaternary: #f7f2f7 !default;

$brown-primary: #705043 !default;
$brown-secondary: #ad907b !default;
$brown-tertiary: #d7ccc8 !default;
$brown-quaternary: #f2ebe8 !default;

$teal-primary: #00695c !default;
$teal-secondary: #4ebaaa !default;
$teal-tertiary: #ceece7 !default;
$teal-quaternary: #ebf7f5 !default;

$pink-primary: #af4448 !default;
$pink-secondary: #e57373 !default;
$pink-tertiary: #ffc2c2 !default;
$pink-quaternary: #ffe7e7 !default;

$orange-primary: #bb4d00 !default;
$orange-secondary: #ffad42 !default;
$orange-tertiary: #ffe97d !default;
$orange-quaternary: #fff4cf !default;

$slate-primary: #29434e !default;
$slate-secondary: #7e9ba5 !default;
$slate-tertiary: #b6c6d2 !default;
$slate-quaternary: #e2e8ed !default;

$indigo-primary: #26418f !default;
$indigo-secondary: #92a6dd !default;
$indigo-tertiary: #dee8ff !default;
$indigo-quaternary: #f2f6ff !default;

$cyan-primary: #007c91 !default;
$cyan-secondary: #65b0bd !default;
$cyan-tertiary: #cce5e9 !default;
$cyan-quaternary: #ebf5f6 !default;

$green-primary: #4b830d !default;
$green-secondary: #84bc49 !default;
$green-tertiary: #dcedc8 !default;
$green-quaternary: #f1f8e9 !default;

$amber-primary: #fbab18 !default;
$amber-secondary: #ffd54f !default;
$amber-tertiary: #ffecb3 !default;
$amber-quaternary: #fff7e1 !default;

// engagement colors
// TODO: TO BE DEFINED
$success: #28a745 !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #343a40 !default;
$muted: #696969 !default;

// alerts
$alert-red: #870808 !default;

// grays
$graydarker: #333 !default;
$graydark: #555 !default;
$gray: #bdbdbd !default;
$graylight: #e0e0e0 !default;
$graylighter: #f0f0f0 !default;
$graylightest: #f5f5f5 !default;

// file colors
$epub: #86b916 !default;
$excel: #207245 !default;
$govd: #5a5a5a !default;
$pdf: #c1272d !default;
$ppt: #d24625 !default;
$fb: #3d5a98 !default;

// font sizes
$fs4: 4rem;
$fs35: 3.5rem;
$fs3: 3rem;
$fs25: 2.5rem;
$fs2: 2rem;
$fs15: 1.5rem;
$fs14: 1.4rem;
$fs13: 1.3rem;
$fs12: 1.2rem;
$fs11: 1.1rem;
$fs1: 1rem;
$fs0875: 0.875rem;
$fs08: 0.8rem;
$fs07: 0.7rem;

// sizes
$s025: 0.25rem;
$s05: 0.5rem;
$s1: 1rem;
$s15: 1.5rem;
$s18: 1.8rem;

// Social Icons Colors
// https://www.lockedowndesign.com/social-media-colors/
// https://brandcolors.net/
$bookmark: #40871b !default;
$cdctv: #0060a9 !default;
$twitter: #00b6f1 !default;
$skype: #00aff0 !default;
$facebook: #3b5998 !default;
$linkedin: #007bb6 !default;
$googleplus: #df4a32 !default;
$youtube: #f00 !default;
$flickrbg: #d4dde2 !default;
$flickrpink: #f40083 !default;
$flickrblue: #006add !default;
$pinterest: #cb2027 !default;
$instagram: #c32aa3 !default;
$vimeo: #45bbff !default;
$tumblr: #35465d !default;
$soundcloud: #f50 !default;
$foursquare: #fc4575 !default;
$dribbble: #ea4c89 !default;
$behance: #053eff !default;
$vine: #00b489 !default;
$stumbleupon: #eb4924 !default;
$rss: #fa9b39 !default;
$snapchat: #fffc00 !default;
$whatsapp: #25d366 !default;
$swarm: #ffb000 !default;
$android: #a4c639 !default;
$yahoo: #430297 !default;
$spotify: #1ed760 !default;
$reddit: #ff5700 !default;
$deviantart: #4a5d4e !default;
$pocket: #ee4056 !default;
$podcast: #724faa !default;
$quora: #a20 !default;
$slideshare: #f7941e !default;
$px500: #0099e5 !default; // 500px
$listly: #df6d46 !default;
$sas: #007cc2 !default;
$stackoverflow: #f48024 !default;
$syndication: #f45a03 !default;

$monospace: menlo, monaco, consolas, liberation mono, courier new, monospace;
$opensans: 'Open Sans', apple-system, blinkmacsystemfont, 'Segoe UI',
  'Helvetica Neue', arial, sans-serif;
