/* images */

img {
  &.img-thumbnail {
    border-radius: 0;

    &.img-left {
      float: left;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }

    &.img-right {
      float: right;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
}
