/* buttons */

.btn {
  text-decoration: none;

  &:focus,
  &.focus {
    // outline: 0;
    @include focusBoxShadow();
  }

  &:hover {
    text-decoration: underline;
  }

  &.btn-success {
    background-color: $green-primary;
    border-color: $green-primary;
    color: $white;

    &:hover {
      background-color: darken($green-primary, 5%);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $green-primary;
      border-color: $green-primary;
    }
  }

  &.btn-danger {
    background-color: $pink-primary;
    border-color: $pink-primary;
    color: $white;

    &:hover {
      background-color: darken($pink-primary, 5%);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $pink-primary;
      border-color: $pink-primary;
    }
  }

  &.btn-warning {
    background-color: $amber-primary;
    border-color: $amber-primary;
    color: $black;

    &:hover {
      background-color: darken($amber-primary, 5%);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $amber-primary;
      border-color: $amber-primary;
    }
  }

  &.btn-info {
    background-color: $secondary;
    border-color: $secondary;
    color: $black;

    &:hover {
      background-color: darken($secondary, 5%);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $secondary;
      border-color: $secondary;
      color: $black;
    }
  }

  &.btn-light {
    background-color: $graylighter;
    border-color: $graylighter;
    color: $black;

    &:hover {
      background-color: darken($graylightest, 5%);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $graylightest;
      border-color: $graylightest;
    }
  }

  &.btn-dark {
    background-color: $black;
    border-color: $black;
    color: $white;

    &:hover {
      background-color: lighten($black, 15%);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $black;
      border-color: $black;
    }
  }

  &.btn-white {
    background-color: $white;
    border-color: $white;
    color: $cdcblue;

    &:hover {
      background-color: darken($white, 5%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    }

    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $white;
      border-color: $white;
    }

    svg {
      fill: $cdcblue;
    }
  }

  &.btn-link {
    color: $cdcblue;
  }

  &.btn-left {
    border-radius: 0;
    padding: 0;
    padding-right: 1rem;

    div {
      vertical-align: middle;
      display: inline-block;
      padding: 0.5rem;
      margin-right: 1rem;
      padding-top: 0.7rem;
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
  }

  &.btn-right {
    border-radius: 0;
    padding: 0;
    padding-left: 1rem;

    div {
      vertical-align: middle;
      display: inline-block;
      padding: 0.5rem;
      margin-left: 1rem;
      padding-top: 0.7rem;
      padding-right: 0.7rem;
      padding-left: 0.7rem;
    }
  }
}

.btn-control {
  background-color: #fff;
  border: 1px solid #ced4da;
}
