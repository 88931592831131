/* 4th Level Nav */

.fourth-level-nav.ordered-list {
  li.list-group-item {
    &:first-child {
      padding-top: 0.5rem;
    }

    border: none !important;
    padding: 0;
  }

  a,
  a:link {
    text-decoration: underline;
  }
}

.fourth-level-nav.ordered-list.disc {
  ol {
    list-style-type: disc !important;
  }
}

.fourth-level-nav.ordered-list.decimal {
  ol {
    list-style-type: decimal !important;
  }
}

.fourth-level-container {
  ol {
    padding-left: 1rem;

    .list-group-item {
      display: list-item;
      margin-left: 1rem;

      a {
        color: $black;
      }
    }
  }

  ul.two-column-list,
  ol.two-column-list {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    list-style-position: inside;
    display: block;

    li.list-group-item:nth-child(6) {
      border-top: 1px dashed #e0e0e0 !important;
    }
  }
}

.tp-related-pages {
  // color: $black;
  // line-height: 1.3rem;

  a,
  a:link {
    text-decoration: none;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }

  // .card-header {
  //     font-weight: 500;
  //     font-size: 1.125rem;
  //     background-color: #f5f5f5;
  // }

  .collapse-link,
  a.collapse-link {
    font-size: 17px;
    font-family: $opensans;
    color: $primary;
  }

  // ol.list-group {
  //     list-style-type: circle;
  // }

  .list-group {
    padding-left: 15px;
    padding-right: 15px;
  }

  .list-group li.list-group-item {
    border: none;
    // border-top: 1px dashed  #e0e0e0;
    padding-left: 0;
    // margin-bottom: 1rem;
    // margin-top: .2rem;
  }

  .list-group-item.active {
    color: $black;
    font-weight: bold;
    background: none !important;

    a.list-group-item-action {
      color: $black;
    }
  }

  .list-group {
    font-weight: 400;
  }

  .list-group li.list-group-item:first-child {
    &:hover {
      border-top: none;
    }
    border-top: none;
  }

  .list-group li.list-group-item:last-child {
    &:hover {
      border-bottom: none;
    }
    border-bottom: none;
  }

  .list-group li.list-group-item:nth-child(6) {
    &:hover {
      border-top: none;
    }
    border-top: none;
  }
}

//+/-

.collapse-icon {
  a.nav-plus:link,
  a.nav-minus:link,
  a.nav-plus:visited,
  a.nav-minus:visited {
    position: absolute;
    z-index: 1071;
    top: 15px;
    right: 5px;
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 0;
    background-color: transparent;
    box-sizing: content-box;
    text-align: center;

    svg {
      fill: $gray;
      width: 100%;
      height: 100%;
      transition: fill 0.5s, transform 0.4s linear;

      transform: rotate(0deg);
    }

    .fi {
      color: $gray;
      font-size: 20px;
      line-height: 1;
      vertical-align: top;
      transition: all 0.5s, transform 0.4s linear;
      transform: rotate(-180deg);
    }

    &.collapsed svg,
    &.collapsed .fi,
    &:active svg,
    &:active .fi {
      transform: rotate(-90deg);
    }
  }

  &.active {
    > a:first-child {
      font-weight: bold;
    }

    ul li {
      a:link,
      a:active,
      a:visited {
        font-weight: normal;
        border-left: 4px solid $graylightest;
      }

      &.active {
        > a:first-child {
          font-weight: bold;
        }
      }
    }

    &.selected {
      > a:first-child {
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 767px) {
  .tp-related-pages:not(.multi-page) {
    display: none;
  }
}
