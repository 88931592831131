.video-toolbar {
  button i {
    color: $white;
  }
}

.video-container {
  position: relative;

  a:active,
  a:focus {
    outline: none;
    border: none;
  }

  .video-container-placeholder svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;

    &:hover {
      opacity: 0.9;
    }
  }
}

.video-container-inline {
  display: inline;
}

.video-container-small {
  position: relative;
  width: 50%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.modal-video {
  max-width: 700px;

  .modal-header {
    padding: 22px;
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .video-links {
    display: grid;

    span {
      display: inherit;
      float: none;
      text-align: left;
    }

    // WCMS produced transcript links are a little different, handle them here
    .video-transcript a.noDecoration.nonHtml {
      display: block;
      margin-bottom: 0.5rem;

      svg.ml-2 {
        margin-left: 5px;
      }
    }
  }

  .video-container-small {
    width: 100%;
  }

  .modal-video {
    width: 100%;
  }
}
