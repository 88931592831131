/* social media */

.socialMediaFeeds {
  padding: 0;
  background: $white;
  border: 1px solid $graylight;

  .card-header {
    padding-left: none;
    background: $white;

    height: 50px;

    svg {
      float: left;
    }

    h4 {
      float: left;
      position: relative;
      left: 10px;
    }
  }

  .card-footer {
    a {
      text-decoration: none;
    }

    a:hover {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }

  svg.social-icon {
    width: 25px;
    height: 25px;
    float: left;
    margin: 0 5px 0 0;
  }

  .post {
    font-size: 17px;
    padding: 10px 0;
    line-height: 30px;
    border-bottom: 1px dashed $graylight;

    &:last-child {
      border-bottom: none;
    }
  }

  .feed-header {
    width: 100%;
    font-weight: 400;
    font-size: 1.375rem;

    a {
      text-decoration: none;
    }
  }
}

.page-share {
  list-style-type: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  ul {
    margin-bottom: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding-left: 0.875rem;
    margin-bottom: 0;

    a {
      display: inline-block;

      .fi {
        font-size: 24px;
        vertical-align: middle;

        &.cdc-icon-fb-round {
          color: $facebook;
        }

        &.cdc-icon-email-round {
          color: $graydarker;
        }

        &.cdc-icon-twitter-round {
          color: $twitter;
        }

        &.cdc-icon-syndication-round {
          color: $syndication;
        }
      }
    }
  }
}

.social-media-footer {
  list-style: none;
  padding-left: 0 !important;

  li {
    margin-bottom: 0;
    width: 100%;
    text-align: center;

    &:not(:first-of-type) {
      border-left: 1px solid $white;
    }

    .fi {
      position: relative;
      top: 3px;
      color: $white;
    }
  }
}

div.socl-hd {
  &.card-header {
    height: auto;

    h4 {
      float: none;
    }
  }
}
