/* audio */

$track-color: $orange-secondary !default;
$thumb-color: $graydarker !default;

$thumb-radius: 12px !default;
$thumb-height: 20px !default;
$thumb-width: 20px !default;
$thumb-shadow-size: 0 !default;
$thumb-shadow-blur: 0 !default;
$thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
$thumb-border-width: 3px !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 0 !default;
$track-shadow-blur: 0 !default;
$track-shadow-color: rgba(0, 0, 0, 0.2) !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 0 !default;
$contrast: 5% !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: pointer;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  cursor: pointer;
  height: $thumb-height;
  width: $thumb-width;
}

.audio-container {
  width: 360px;

  .scrub-range-slider {
    width: 100%;
  }
}

.audio-player,
.audio-player + div {
  // height: 50px;
  overflow: hidden;

  .btn {
    border-radius: 0;
  }

  .btn-secondary:focus,
  input[type='range']:focus {
    box-shadow: none;
  }

  .scrub-range-slider {
    width: 100%;
    padding: 0;
    margin: 0 !important;
  }

  .volume-range-slider {
    min-width: 100px;
    padding: 0;
    margin: 0 !important;

    &[type='range']::-webkit-slider-runnable-track {
      background: $orange-primary;

      &:focus,
      &:active {
        background: lighten($orange-primary, $contrast);
      }
    }

    &[type='range']:focus::-webkit-slider-runnable-track {
      background: lighten($orange-primary, $contrast);
    }

    &[type='range']::-ms-fill-upper,
    &[type='range']::-ms-fill-lower {
      background: $orange-primary;
    }

    &[type='range']:focus::-ms-fill-upper,
    &[type='range']:focus::-ms-fill-lower {
      background: lighten($orange-primary, $contrast);
    }
  }

  .icon {
    width: 28px;
    height: 28px;

    &.fi {
      vertical-align: middle;
    }
  }

  [type='range'] {
    -webkit-appearance: none;
    margin: $thumb-height / 2 0;
    width: $track-width;
    background: transparent;

    &:focus {
      outline: 0;

      &::-webkit-slider-runnable-track {
        background: lighten($track-color, $contrast);
      }

      &::-ms-fill-lower {
        background: $orange-primary;
      }

      &::-ms-fill-upper {
        background: lighten($track-color, $contrast);
      }
    }

    &::-webkit-slider-runnable-track {
      @include track;
      @include shadow(
        $track-shadow-size,
        $track-shadow-blur,
        $track-shadow-color
      );
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      margin-top: ((-$track-border-width * 2 + $track-height) / 2) -
        ($thumb-height / 2);
    }

    &::-moz-range-track {
      @include track;
      @include shadow(
        $track-shadow-size,
        $track-shadow-blur,
        $track-shadow-color
      );
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-track {
      @include track;
      background: transparent;
      border-color: transparent;
      border-width: ($thumb-height / 2) 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      @include shadow(
        $track-shadow-size,
        $track-shadow-blur,
        $track-shadow-color
      );
      background: $orange-primary;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius * 2;
    }

    &::-ms-fill-upper {
      @include shadow(
        $track-shadow-size,
        $track-shadow-blur,
        $track-shadow-color
      );
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius * 2;
    }

    &::-ms-thumb {
      @include thumb;
      margin-top: 0;
    }
  }
}

.audio-player + div {
  margin-top: -9px;
  z-index: 998;
  position: relative;
}

@media (max-width: 767px) {
  .audio-player {
    .volume-range-button {
      display: none;
    }
    // hide the scrubber
    + div {
      display: none;
    }
  }
}

//hide mute button for mobile browsers
@media (max-width: 767.98px) {
  .audio-player {
    .btn-volume-toggle {
      visibility: hidden;
      display: none;
    }
  }
}
