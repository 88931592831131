/* jumbotrons */

a.jumbotron-btn {
  color: inherit;
  text-decoration: initial;

  .btn {
    &:hover {
      text-decoration: none;
    }
  }
}

.jumbotron {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 15px;
  border-radius: 0;
  position: relative;
  // overflow: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  &.jumbotron-light {
    .jumbotron-overlay {
      background-color: rgba(255, 255, 255, 0.75);
      color: $black;
    }
  }

  &.jumbotron-dark {
    .jumbotron-overlay {
      background-color: rgba(0, 0, 0, 0.75);
      color: $white;

      a:not(.btn) {
        color: $white;
      }
    }
  }

  &.tb-rc {
    //transparent-border, rounded corners
    border-radius: 0.25rem;
    //border: 0 solid transparent !important;
    .card-body {
      border-radius: 0.25rem;
    }
  }

  .jumbotron-overlay {
    padding: 1rem;
    position: absolute;
    overflow: hidden;

    &.overlay-fullheight {
      max-height: 100%;
      height: 100%;
    }

    &.fullheight {
      max-height: 100%;
      height: 100%;

      &[class*='m-'] {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: auto;
      }

      &.m-1 {
        top: 0.25rem;
        bottom: 0.25rem;
      }

      &.m-2 {
        top: 0.5rem;
        bottom: 0.5rem;
      }

      &.m-3 {
        top: 1rem;
        bottom: 1rem;
      }

      &.m-4 {
        top: 2rem;
        bottom: 2rem;
      }

      &.m-5 {
        top: 3rem;
        bottom: 3rem;
      }
    }

    &.overlay-left,
    &.overlay-top-left {
      left: 0;
      top: 0;
    }

    &.overlay-right,
    &.overlay-top-right {
      right: 0;
      top: 0;
    }

    &.overlay-bottom-left {
      left: 0;
      bottom: 0;
    }

    &.overlay-bottom-right {
      bottom: 0;
      right: 0;
    }

    &.overlay-bottom {
      left: 0;
      bottom: 0;
      right: 0;
    }

    &.overlay-middle {
      left: 0;
      top: 50%;
      transform: translateY(-50%) !important;
      right: 0;
    }

    &.overlay-middle-left {
      left: 0;
      top: 50%;
      transform: translateY(-50%) !important;
    }

    &.overlay-middle-right {
      top: 50%;
      transform: translateY(-50%) !important;
      right: 0;
    }
  }
}

// large
@media (min-width: 992px) and (max-width: 1199.98px) {
  .jumbotron {
    .display-5,
    .display-4,
    .display-3 {
      font-size: 1.5rem;
    }
  }
}

// xs - medium
@media (max-width: 991.98px) {
  .jumbotron {
    img {
      padding-bottom: 0 !important;
    }

    .display-5,
    .display-4,
    .display-3 {
      font-size: 1.375rem; // .h4
    }

    .jumbotron-overlay {
      &[class*='overlay-'] {
        top: 0 !important;
        left: 0;
        bottom: 0;
        height: auto;
        transform: none !important;
        position: relative;
        background: $graylight;
        color: $black;

        &.m-5 {
          margin: 0 !important;
        }

        &.ml-5 {
          margin-left: 0 !important;
        }

        &.mr-5 {
          margin-right: 0 !important;
        }
      }

      a {
        text-decoration: none;
      }
    }

    .m-5 {
      margin-top: 0 !important;
    }

    .w-50,
    .w-33 {
      width: auto !important;
    }
  }
}
