@import './modules/tp4/scss/app.scss';
//@import './modules/tp4/scss/variables';

.bg__white {
  background: white;
  overflow: auto;
  min-height: 600px;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: #075290;
  &:hover {
    text-decoration: underline;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: inherit;
}

.fi {
  transition: all 0.5s;
}

.site-title {
  &.border-none {
    border-bottom: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
