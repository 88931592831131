/* navigation */
nav {
  ul.tp-nav-main,
  .mobile-section-nav {
    //removing the ability to tap a small padding gap that cause the mobile menu to behave oddly
    .dropdown-menu {
      pointer-events: none;
      a {
        pointer-events: all;
      }
    }

    // unstyle
    .list-group-item {
      border: inherit;
      background-color: inherit;
    }

    li {
      border: none;
      margin: 0;
      padding: 0;
      position: relative;

      a,
      ul li a {
        border-left: 4px solid $white;
        background-color: $white;

        @include mainNavLinks();
      }

      a.nav-expandcollapse .fi {
        transition: all 0.5s;
      }

      a.nav-plus:link,
      a.nav-minus:link,
      a.nav-plus:visited,
      a.nav-minus:visited {
        position: absolute;
        z-index: 998;
        top: 11px;
        right: 5px;
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 0;
        background-color: transparent;
        border: inherit;
        box-sizing: content-box;
        text-align: center;

        svg {
          fill: $gray;
          width: 100%;
          height: 100%;
          transition: fill 0.5s, transform 0.4s linear;

          transform: rotate(0deg);
        }

        .fi {
          color: $gray;
          font-size: 20px;
        }

        &.collapsed svg,
        &:active svg {
          transform: rotate(-90deg);
        }
      }

      &.active {
        > a:first-child {
          font-weight: bold;
        }

        ul li {
          a:link,
          a:active,
          a:visited {
            font-weight: normal;
            border-left: 4px solid $graylightest;
          }

          &.active {
            > a:first-child {
              font-weight: bold;
            }
          }
        }

        &.selected {
          > a:first-child {
            font-weight: bold;
          }
        }
      }

      // second tier nav
      ul {
        padding-left: 0;

        li a {
          border-left: 4px solid $graylightest;
        }

        li,
        li.active {
          margin-left: 20px;

          a {
            font-size: 0.9rem;
          }

          // third tier nav
          ul li {
            a {
              font-size: 0.8rem;
            }

            a.nav-plus:link,
            a.nav-minus:link {
              display: none;
            }
            // hide 4th level
            ul {
              display: none;
            }
          }
        }
      }
    }
  }
}

.modalMask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $black;
  opacity: 0.5;
  z-index: 970;
}
