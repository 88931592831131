[class*='cc-'] {
  li,
  p {
    break-inside: avoid-column;
  }
}

.cc-1 {
  column-count: 1;
}

.cc-2 {
  column-count: 2;
}

.cc-3 {
  column-count: 3;
}

.cc-4 {
  column-count: 4;
}

@media (min-width: 576px) {
  .cc-sm-1 {
    column-count: 1;
  }

  .cc-sm-2 {
    column-count: 2;
  }

  .cc-sm-3 {
    column-count: 3;
  }

  .cc-sm-4 {
    column-count: 4;
  }
}

@media (min-width: 768px) {
  .cc-md-1 {
    column-count: 1;
  }

  .cc-md-2 {
    column-count: 2;
  }

  .cc-md-3 {
    column-count: 3;
  }

  .cc-md-4 {
    column-count: 4;
  }
}

@media (min-width: 992px) {
  .cc-lg-1 {
    column-count: 1;
  }

  .cc-lg-2 {
    column-count: 2;
  }

  .cc-lg-3 {
    column-count: 3;
  }

  .cc-lg-4 {
    column-count: 4;
  }
}

@media (min-width: 1200px) {
  .cc-xl-1 {
    column-count: 1;
  }

  .cc-xl-2 {
    column-count: 2;
  }

  .cc-xl-3 {
    column-count: 3;
  }

  .cc-xl-4 {
    column-count: 4;
  }
}

@media (min-width: 1440px) {
  .cc-xxl-1 {
    column-count: 1;
  }

  .cc-xxl-2 {
    column-count: 2;
  }

  .cc-xxl-3 {
    column-count: 3;
  }

  .cc-xxl-4 {
    column-count: 4;
  }
}

// column gap
.cg-1 {
  column-gap: 0.25rem;
}

.cg-2 {
  column-gap: 0.5rem;
}

.cg-3 {
  column-gap: 1rem;
}

.cg-4 {
  column-gap: 1.5rem;
}

.cg-5 {
  column-gap: 2rem;
}

// column rule style
.crs-solid {
  column-rule-style: solid;
}

.crs-dotted {
  column-rule-style: dotted;
}

.crs-dashed {
  column-rule-style: dashed;
}

// column rule width
.crw-1 {
  column-rule-width: 1px;
}

.crw-2 {
  column-rule-width: 2px;
}

.crw-3 {
  column-rule-width: 3px;
}

.crw-4 {
  column-rule-width: 4px;
}

.crw-5 {
  column-rule-width: 5px;
}

// column rule color
.crc-p {
  column-rule-color: $primary;
}

.crc-s {
  column-rule-color: $secondary;
}

// column span
.cs-1 {
  column-span: 1;
}

.cs-all {
  column-span: all;
}

//percentage columns
//12/12 = 1
//.w-col12 { width: calc( ( 12 / 12 ) * 100 ); }
.w-col12 {
  width: 100%;
}

//11/12 = .916
//.w-col11 { width: calc( ( 11 / 12 ) * 100 ); }
.w-col11 {
  width: 91.6%;
}

//10/12 = .83
//.w-col10 { width: calc( ( 10 / 12 ) * 100 ); }
.w-col10 {
  width: 83%;
}

//9/12 = .75
//.w-col9 { width: calc( ( 9 / 12 ) * 100 ); }
.w-col9 {
  width: 75%;
}

//8/12 = .66
//.w-col8 { width: calc( ( 8 / 12 ) * 100 ); }
.w-col8 {
  width: 66.6%;
}

//7/12 = .58
//.w-col7 { width: calc( ( 7 / 12 ) * 100 ); }
.w-col7 {
  width: 58.3%;
}

//6/12 = .5
//.w-col6 { width: calc( ( 6 / 12 ) * 100 ); }
.w-col6 {
  width: 50%;
}

//5/12 = .416
//.w-col5 { width: calc( ( 5 / 12 ) * 100 ); }
.w-col5 {
  width: 41.6%;
}

//4/12 = .33
//.w-col4 { width: calc( ( 4 / 12 ) * 100 ); }
.w-col4 {
  width: 33.3%;
}

//3/12 = .25
//.w-col3 { width: calc( ( 3 / 12 ) * 100 ); }
.w-col3 {
  width: 25%;
}

//2/12 = .16
//.w-col2 { width: calc( ( 2 / 12 ) * 100 ); }
.w-col2 {
  width: 16.6%;
}

//1/12 = .083
//.w-col1 { width: calc( ( 1 / 12 ) * 100 ); }
.w-col1 {
  width: 8.3%;
}

@media only screen and (min-width: 769px) {
  .w-md-col12 {
    width: 100%;
  }

  .w-md-col11 {
    width: 91.6%;
  }

  .w-md-col10 {
    width: 83%;
  }

  .w-md-col9 {
    width: 75%;
  }

  .w-md-col8 {
    width: 66.6%;
  }

  .w-md-col7 {
    width: 58.3%;
  }

  .w-md-col6 {
    width: 50%;
  }

  .w-md-col5 {
    width: 41.6%;
  }

  .w-md-col4 {
    width: 33.3%;
  }

  .w-md-col3 {
    width: 25%;
  }

  .w-md-col2 {
    width: 16.6%;
  }

  .w-md-col1 {
    width: 8.3%;
  }
}
