/* lists */

.lst-la,
.lla {
  list-style-type: lower-alpha;
}

.lst-lg,
.llg {
  list-style-type: lower-greek;
}

.lst-lr,
.llr {
  list-style-type: lower-roman;
}

.lst-ua,
.lua {
  list-style-type: upper-alpha;
}

.lst-ur,
.lur {
  list-style-type: upper-roman;
}
// .list-block, .list-group, .lb, .lg { }
.lsp-in {
  list-style-position: inside;
}

.lsp-out {
  list-style-position: outside;
  padding-left: 1rem;

  li {
    margin-left: 1.5rem;
  }
} //li { margin-left: 1rem; padding-left: .4rem; }
