/* alerts */

.alert {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: $opensans;
    a {
      text-decoration: none;
    }
  }

  .h4 {
    margin-top: 0;
  }

  //Container Padding and Margin
  .padding-pagealert {
    padding-left: 10px;
    padding-top: 4px;
  }

  .margin-pagealert {
    margin-top: 8px;
  }

  //Reverse Link Underline
  a.link-pagealert {
    &:link {
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  // alert box font color
  p {
    color: $black;
  }

  svg {
    width: 36px;
    height: 36px;
  }

  .row .fi {
    font-size: 36px;
    line-height: 1;
  }

  &.alert-success {
    background-color: $green-tertiary;
    border-top: 5px solid $green-primary;
    //border-bottom: 1px solid $green-primary;
    hr {
      border-top-color: $gray;
    }

    svg {
      fill: $green-primary;
    }

    .row .fi {
      color: $green-primary;
    }

    a.btn .fi.x16 {
      vertical-align: baseline;
    }
  }

  &.alert-danger {
    background-color: $pink-tertiary;
    border-top: 5px solid $pink-primary;
    //border-bottom: 1px solid $pink-primary;
    hr {
      border-top-color: $gray;
    }

    svg {
      fill: $pink-primary;
    }

    .row .fi {
      color: $pink-primary;
    }

    a.btn .fi.x16 {
      vertical-align: baseline;
    }
  }

  &.alert-warning {
    background-color: $amber-tertiary;
    border-top: 5px solid $amber-primary;
    //border-bottom: 1px solid $amber-primary;
    hr {
      border-top-color: $gray;
    }

    svg {
      fill: $amber-primary;
    }

    .row .fi {
      color: $amber-primary;
    }

    a.btn .fi.x16 {
      vertical-align: baseline;
    }
  }

  &.alert-info {
    background-color: $tertiary;
    border-top: 5px solid $primary;
    //border-bottom: 1px solid $primary;
    hr {
      border-top-color: $gray;
    }

    svg {
      fill: $primary;
    }

    .row div .fi {
      color: $primary;
    }

    a.btn .fi.x16 {
      vertical-align: baseline;
    }
  }

  &.alert-archive {
    background-color: $graylighter;
    border-top: 5px solid $graydarker;
    //border-bottom: 1px solid $gray;
    hr {
      border-top-color: $gray;
    }

    svg {
      fill: $graydarker;
    }

    .row .fi {
      color: $graydarker;
    }

    a.btn .fi.x16 {
      vertical-align: baseline;
    }
  }
}
