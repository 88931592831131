/* header */

header {
  .cdc-logo a {
    display: block;

    svg {
      color: black;
      fill: black;
      max-width: 100%;
    }
  }

  .svg-icon {
    width: 24px;
    height: 24px;
  }

  .cdc-logo .logo-small {
    margin-top: 10px;
  }

  .dropdown-submenu {
    position: relative;
  }
}

.site-title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: $white;
    vertical-align: text-top;
  }

  a:hover * {
    text-decoration: underline;
  }

  .tagline {
    margin-bottom: 0;
  }

  .site-title-image {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;

    @include breakpoint(lg) {
      text-align: right;
    }

    img {
      max-height: 35px;
    }
  }
}

@media (max-width: 768px) {
  header {
    .svg-icon {
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width: 767px) {
  .site-title {
    .site-title-image {
      padding: 10px 0 10px 6px;

      //img {position: relative;}
    }
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .site-title {
    .site-title-image {
      padding: 10px 0 10px 6px;

      img {
        position: relative;
      }
    }
  }
}

@media (max-width: 991px) {
  .site-title .col {
    padding-left: 5px;
  }

  .header-wrapper .col {
    padding-left: 0;
  }
}

@media only screen and (max-width: 374px) {
  header {
    .cdc-logo {
      max-width: 85%;
    }
  }
}

@media screen and (max-width: 475px) {
  .small-search button {
    padding-right: 0;
    padding-left: 0;
  }
}
