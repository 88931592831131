/* merriweather-regular - latin */

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/merriweather-v19-latin-regular.eot);

  /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
    url('../fonts/merriweather-v19-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/merriweather-v19-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/merriweather-v19-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/merriweather-v19-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/merriweather-v19-latin-regular.svg#Merriweather')
      format('svg');

  /* Legacy iOS */
}

/* open-sans-regular - latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular.eot');

  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v15-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v15-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v15-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg');

  /* Legacy iOS */
}
