/* tables */

.d-table-cell {
  display: table-cell;
}

.inline-table {
  display: inline-table;
}

.fixed-table {
  table-layout: fixed;
}

@media (max-width: 768px) {
  .opt-in {
    .expander {
      text-align: center;
    }

    .faded {
      opacity: 0.5;
    }

    tbody > tr {
      display: none;

      &.expanded {
        display: table-row;
      }
    }

    &.expanded {
      tbody > tr {
        display: table-row;
      }

      + div > .expander {
        display: none;
      }

      .faded {
        opacity: 1;
      }
    }
  }
}

table {
  &.table {
    .thead-light {
      th {
        color: $black;
      }
    }
  }

  caption {
    //https://sd5etr.axshare.com/#g=1&p=tables
    color: $graydark;

    &.caption-top {
      caption-side: top;
    }

    &.caption-bottom {
      caption-side: bottom;
      font-size: 0.8rem;
      font-style: italic;
      color: $graydark;
    }
  }

  &.table-striped tbody tr:nth-of-type(even) {
    background-color: $white;
    color: $black;
  }
}

@media (min-width: 769px) {
  table {
    &.table-fixed {
      min-width: 600px;

      table,
      tr,
      th,
      td {
        border-collapse: collapse;
      }

      thead {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      tbody {
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
        display: block;
        overflow-x: hidden;
        overflow-y: overlay;
      }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      th:nth-child(n) {
        width: auto;
      }

      &.mh-200 tbody {
        max-height: 200px;
      }

      &.mh-400 tbody {
        max-height: 400px;
      }

      &.mh-600 tbody {
        max-height: 600px;
      }

      &.mh-800 tbody {
        max-height: 800px;
      }
    }
  }
}
