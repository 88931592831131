.page-format {
  background-color: $graylightest;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 2rem; // WCMSRD-7956
  margin-right: 1rem;

  a {
    display: inline-block;
    margin-left: 1rem;
    color: $black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
}
