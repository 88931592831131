/* Generated by grunt-webfont */
/* stylelint-disable */
/* Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css */


	
	
	@font-face {
		font-family:"cdciconfont";
		src:url("../fonts/cdciconfont.eot?2747808d2c4ae8c1059745ae5eddb65e");
		src:url("../fonts/cdciconfont.eot?2747808d2c4ae8c1059745ae5eddb65e#iefix") format("embedded-opentype"),
		url("../fonts/cdciconfont.woff2?2747808d2c4ae8c1059745ae5eddb65e") format("woff2"),
		url("../fonts/cdciconfont.woff?2747808d2c4ae8c1059745ae5eddb65e") format("woff"),
		url("../fonts/cdciconfont.ttf?2747808d2c4ae8c1059745ae5eddb65e") format("truetype"),
		url("../fonts/cdciconfont.svg?2747808d2c4ae8c1059745ae5eddb65e#icons") format("svg");
		font-weight:normal;
		font-style:normal;
	}



/* Bootstrap Overrides */
[class^="cdc-icon-"]:before,
[class*=" cdc-icon-"]:before {
   font-family:"cdciconfont";
   display:inline-block;
   line-height:1;
   font-weight:normal;
   font-style:normal;
   speak:none;
   text-decoration:inherit;
   text-transform:none;
   text-rendering:auto;
   -webkit-font-smoothing:antialiased;
   -moz-osx-font-smoothing:grayscale;
}



a [class^="cdc-icon-"],
a [class*=" cdc-icon-"] {
  display:inline-block;
  text-decoration:inherit;
}
/* Makes the font 33% larger relative to the icon container */
.cdc-icon-large:before {
	vertical-align:top;
	font-size:1.333em;
}
/* Keeps button heights with and without icons the same */
.btn [class^="cdc-icon-"],
.btn [class*=" cdc-icon-"] {
	line-height:0.9em;
}
li [class^="cdc-icon-"],
li [class*=" cdc-icon-"] {
	display:inline-block;
	//width:1.25em;
	min-width:1.25em;
	text-align:center;
}
/* 1.5 increased font size for cdc-icon-large * 1.25 width */
li .cdc-icon-large[class^="cdc-icon-"],
li .cdc-icon-large[class*=" cdc-icon-"] {
	//width:1.875em;
	min-width:1.875em;
}
li[class^="cdc-icon-"],
li[class*=" cdc-icon-"] {
	margin-left:0;
	list-style-type:none;
}
li[class^="cdc-icon-"]:before,
li[class*=" cdc-icon-"]:before {
	text-indent:-2em;
	text-align:center;
}
li[class^="cdc-icon-"].cdc-icon-large:before,
li[class*=" cdc-icon-"].cdc-icon-large:before {
	text-indent:-1.333em;
}

/* Icons */
.cdc-icon-alert_01:before {
		  content:"\f101";
	  }

.cdc-icon-alert_02:before {
		  content:"\f102";
	  }

.cdc-icon-alert_03:before {
		  content:"\f103";
	  }

.cdc-icon-alert_04:before {
		  content:"\f104";
	  }

.cdc-icon-alert_05:before {
		  content:"\f105";
	  }

.cdc-icon-alert_06:before {
		  content:"\f106";
	  }

.cdc-icon-allergy_01:before {
		  content:"\f107";
	  }

.cdc-icon-animal_01:before {
		  content:"\f108";
	  }

.cdc-icon-animal_02:before {
		  content:"\f109";
	  }

.cdc-icon-animal_03:before {
		  content:"\f10a";
	  }

.cdc-icon-audio_01:before {
		  content:"\f10b";
	  }

.cdc-icon-audio_02:before {
		  content:"\f10c";
	  }

.cdc-icon-audio_03:before {
		  content:"\f10d";
	  }

.cdc-icon-audio_04:before {
		  content:"\f10e";
	  }

.cdc-icon-audio_05:before {
		  content:"\f10f";
	  }

.cdc-icon-badge_01:before {
		  content:"\f110";
	  }

.cdc-icon-badge_02:before {
		  content:"\f111";
	  }

.cdc-icon-badge_03:before {
		  content:"\f112";
	  }

.cdc-icon-badge_04:before {
		  content:"\f113";
	  }

.cdc-icon-badge_05:before {
		  content:"\f114";
	  }

.cdc-icon-bag_01:before {
		  content:"\f115";
	  }

.cdc-icon-bat_1:before {
		  content:"\f116";
	  }

.cdc-icon-book_01:before {
		  content:"\f117";
	  }

.cdc-icon-book_02:before {
		  content:"\f118";
	  }

.cdc-icon-book_03:before {
		  content:"\f119";
	  }

.cdc-icon-book_04:before {
		  content:"\f11a";
	  }

.cdc-icon-bookmark_01:before {
		  content:"\f11b";
	  }

.cdc-icon-brain_01:before {
		  content:"\f11c";
	  }

.cdc-icon-building_01:before {
		  content:"\f11d";
	  }

.cdc-icon-building_02:before {
		  content:"\f11e";
	  }

.cdc-icon-building_03:before {
		  content:"\f11f";
	  }

.cdc-icon-building_04:before {
		  content:"\f120";
	  }

.cdc-icon-calendar_01:before {
		  content:"\f121";
	  }

.cdc-icon-cancer_02:before {
		  content:"\f122";
	  }

.cdc-icon-cat_1:before {
		  content:"\f123";
	  }

.cdc-icon-chart_01:before {
		  content:"\f124";
	  }

.cdc-icon-chart_02:before {
		  content:"\f125";
	  }

.cdc-icon-chart_03:before {
		  content:"\f126";
	  }

.cdc-icon-chat_01:before {
		  content:"\f127";
	  }

.cdc-icon-chat_02:before {
		  content:"\f128";
	  }

.cdc-icon-chat_03:before {
		  content:"\f129";
	  }

.cdc-icon-chat_04:before {
		  content:"\f12a";
	  }

.cdc-icon-chat_05:before {
		  content:"\f12b";
	  }

.cdc-icon-chicken_1:before {
		  content:"\f12c";
	  }

.cdc-icon-cleaning_01:before {
		  content:"\f12d";
	  }

.cdc-icon-cleaning_02:before {
		  content:"\f12e";
	  }

.cdc-icon-clipboard_01:before {
		  content:"\f12f";
	  }

.cdc-icon-clock_01:before {
		  content:"\f130";
	  }

.cdc-icon-clothes_01:before {
		  content:"\f131";
	  }

.cdc-icon-cloud_01:before {
		  content:"\f132";
	  }

.cdc-icon-comment3:before {
		  content:"\f133";
	  }

.cdc-icon-contact:before {
		  content:"\f134";
	  }

.cdc-icon-crowd:before {
		  content:"\f135";
	  }

.cdc-icon-data_01:before {
		  content:"\f136";
	  }

.cdc-icon-device_01:before {
		  content:"\f137";
	  }

.cdc-icon-device_02:before {
		  content:"\f138";
	  }

.cdc-icon-device_03:before {
		  content:"\f139";
	  }

.cdc-icon-device_04:before {
		  content:"\f13a";
	  }

.cdc-icon-digital-thermometer:before {
		  content:"\f13b";
	  }

.cdc-icon-direction_01:before {
		  content:"\f13c";
	  }

.cdc-icon-dog_1:before {
		  content:"\f13d";
	  }

.cdc-icon-download_01:before {
		  content:"\f13e";
	  }

.cdc-icon-ecig_01:before {
		  content:"\f13f";
	  }

.cdc-icon-ecig_02:before {
		  content:"\f140";
	  }

.cdc-icon-ecig_03:before {
		  content:"\f141";
	  }

.cdc-icon-ecigs:before {
		  content:"\f142";
	  }

.cdc-icon-edit_01:before {
		  content:"\f143";
	  }

.cdc-icon-email_01:before {
		  content:"\f144";
	  }

.cdc-icon-email_02:before {
		  content:"\f145";
	  }

.cdc-icon-email_03:before {
		  content:"\f146";
	  }

.cdc-icon-eye_01:before {
		  content:"\f147";
	  }

.cdc-icon-fig1_1:before {
		  content:"\f148";
	  }

.cdc-icon-flea_fly:before {
		  content:"\f149";
	  }

.cdc-icon-flea:before {
		  content:"\f14a";
	  }

.cdc-icon-food_01:before {
		  content:"\f14b";
	  }

.cdc-icon-food_02:before {
		  content:"\f14c";
	  }

.cdc-icon-food_03:before {
		  content:"\f14d";
	  }

.cdc-icon-food_04:before {
		  content:"\f14e";
	  }

.cdc-icon-food_05:before {
		  content:"\f14f";
	  }

.cdc-icon-food_06:before {
		  content:"\f150";
	  }

.cdc-icon-globe_01:before {
		  content:"\f151";
	  }

.cdc-icon-globe_02:before {
		  content:"\f152";
	  }

.cdc-icon-globe_03:before {
		  content:"\f153";
	  }

.cdc-icon-grid_01:before {
		  content:"\f154";
	  }

.cdc-icon-handwashing:before {
		  content:"\f155";
	  }

.cdc-icon-hazard_01:before {
		  content:"\f156";
	  }

.cdc-icon-hazard_02:before {
		  content:"\f157";
	  }

.cdc-icon-hazard_03:before {
		  content:"\f158";
	  }

.cdc-icon-hazard_04:before {
		  content:"\f159";
	  }

.cdc-icon-hazard_05:before {
		  content:"\f15a";
	  }

.cdc-icon-heart_01:before {
		  content:"\f15b";
	  }

.cdc-icon-home_01:before {
		  content:"\f15c";
	  }

.cdc-icon-hospital_01:before {
		  content:"\f15d";
	  }

.cdc-icon-idea_01:before {
		  content:"\f15e";
	  }

.cdc-icon-kidney_01:before {
		  content:"\f15f";
	  }

.cdc-icon-lab_01:before {
		  content:"\f160";
	  }

.cdc-icon-lab_02:before {
		  content:"\f161";
	  }

.cdc-icon-lab_03:before {
		  content:"\f162";
	  }

.cdc-icon-lab_04:before {
		  content:"\f163";
	  }

.cdc-icon-lab_05:before {
		  content:"\f164";
	  }

.cdc-icon-lab_06:before {
		  content:"\f165";
	  }

.cdc-icon-lab_07:before {
		  content:"\f166";
	  }

.cdc-icon-link_01:before {
		  content:"\f167";
	  }

.cdc-icon-lite_00:before {
		  content:"\f168";
	  }

.cdc-icon-lite_01:before {
		  content:"\f169";
	  }

.cdc-icon-lite_02:before {
		  content:"\f16a";
	  }

.cdc-icon-lite_03:before {
		  content:"\f16b";
	  }

.cdc-icon-lite_04:before {
		  content:"\f16c";
	  }

.cdc-icon-lite_05:before {
		  content:"\f16d";
	  }

.cdc-icon-lite_06:before {
		  content:"\f16e";
	  }

.cdc-icon-lite_07:before {
		  content:"\f16f";
	  }

.cdc-icon-lite_08:before {
		  content:"\f170";
	  }

.cdc-icon-lite_09:before {
		  content:"\f171";
	  }

.cdc-icon-logo_01:before {
		  content:"\f172";
	  }

.cdc-icon-map_01:before {
		  content:"\f173";
	  }

.cdc-icon-map_02:before {
		  content:"\f174";
	  }

.cdc-icon-mask:before {
		  content:"\f175";
	  }

.cdc-icon-medical_01:before {
		  content:"\f176";
	  }

.cdc-icon-medical_02:before {
		  content:"\f177";
	  }

.cdc-icon-medical_03:before {
		  content:"\f178";
	  }

.cdc-icon-medical_04:before {
		  content:"\f179";
	  }

.cdc-icon-medical_05:before {
		  content:"\f17a";
	  }

.cdc-icon-medical_06:before {
		  content:"\f17b";
	  }

.cdc-icon-medical_07:before {
		  content:"\f17c";
	  }

.cdc-icon-medical_08:before {
		  content:"\f17d";
	  }

.cdc-icon-medical_09:before {
		  content:"\f17e";
	  }

.cdc-icon-medical_10:before {
		  content:"\f17f";
	  }

.cdc-icon-medical_11:before {
		  content:"\f180";
	  }

.cdc-icon-money_01:before {
		  content:"\f181";
	  }

.cdc-icon-no_01:before {
		  content:"\f182";
	  }

.cdc-icon-paper_01:before {
		  content:"\f183";
	  }

.cdc-icon-paper_02:before {
		  content:"\f184";
	  }

.cdc-icon-paper_03:before {
		  content:"\f185";
	  }

.cdc-icon-people_01:before {
		  content:"\f186";
	  }

.cdc-icon-people_02:before {
		  content:"\f187";
	  }

.cdc-icon-people_03:before {
		  content:"\f188";
	  }

.cdc-icon-people_04:before {
		  content:"\f189";
	  }

.cdc-icon-people_05:before {
		  content:"\f18a";
	  }

.cdc-icon-people_06:before {
		  content:"\f18b";
	  }

.cdc-icon-people_07:before {
		  content:"\f18c";
	  }

.cdc-icon-people_08:before {
		  content:"\f18d";
	  }

.cdc-icon-people_09:before {
		  content:"\f18e";
	  }

.cdc-icon-phone_01:before {
		  content:"\f18f";
	  }

.cdc-icon-photo_01:before {
		  content:"\f190";
	  }

.cdc-icon-photo_02:before {
		  content:"\f191";
	  }

.cdc-icon-photo_03:before {
		  content:"\f192";
	  }

.cdc-icon-photo_04:before {
		  content:"\f193";
	  }

.cdc-icon-photo_05:before {
		  content:"\f194";
	  }

.cdc-icon-pig_1:before {
		  content:"\f195";
	  }

.cdc-icon-pin_01:before {
		  content:"\f196";
	  }

.cdc-icon-pin_02:before {
		  content:"\f197";
	  }

.cdc-icon-preschool:before {
		  content:"\f198";
	  }

.cdc-icon-presentation_01:before {
		  content:"\f199";
	  }

.cdc-icon-question_01:before {
		  content:"\f19a";
	  }

.cdc-icon-question_02:before {
		  content:"\f19b";
	  }

.cdc-icon-repellent:before {
		  content:"\f19c";
	  }

.cdc-icon-scale_01:before {
		  content:"\f19d";
	  }

.cdc-icon-scale_02:before {
		  content:"\f19e";
	  }

.cdc-icon-search_01:before {
		  content:"\f19f";
	  }

.cdc-icon-search_02:before {
		  content:"\f1a0";
	  }

.cdc-icon-search_03:before {
		  content:"\f1a1";
	  }

.cdc-icon-signal_01:before {
		  content:"\f1a2";
	  }

.cdc-icon-smoking_01:before {
		  content:"\f1a3";
	  }

.cdc-icon-sol_00:before {
		  content:"\f1a4";
	  }

.cdc-icon-sol_01:before {
		  content:"\f1a5";
	  }

.cdc-icon-sol_02:before {
		  content:"\f1a6";
	  }

.cdc-icon-sol_03:before {
		  content:"\f1a7";
	  }

.cdc-icon-sol_04:before {
		  content:"\f1a8";
	  }

.cdc-icon-sol_05:before {
		  content:"\f1a9";
	  }

.cdc-icon-sol_06:before {
		  content:"\f1aa";
	  }

.cdc-icon-sol_07:before {
		  content:"\f1ab";
	  }

.cdc-icon-sol_08:before {
		  content:"\f1ac";
	  }

.cdc-icon-sol_09:before {
		  content:"\f1ad";
	  }

.cdc-icon-sports_01:before {
		  content:"\f1ae";
	  }

.cdc-icon-stack_01:before {
		  content:"\f1af";
	  }

.cdc-icon-stack_02:before {
		  content:"\f1b0";
	  }

.cdc-icon-stack_03:before {
		  content:"\f1b1";
	  }

.cdc-icon-stack_04:before {
		  content:"\f1b2";
	  }

.cdc-icon-symbol_01:before {
		  content:"\f1b3";
	  }

.cdc-icon-symbol_02:before {
		  content:"\f1b4";
	  }

.cdc-icon-symptom1:before {
		  content:"\f1b5";
	  }

.cdc-icon-symptom2:before {
		  content:"\f1b6";
	  }

.cdc-icon-symptom3:before {
		  content:"\f1b7";
	  }

.cdc-icon-tick:before {
		  content:"\f1b8";
	  }

.cdc-icon-tools_01:before {
		  content:"\f1b9";
	  }

.cdc-icon-tools_02:before {
		  content:"\f1ba";
	  }

.cdc-icon-tools_03:before {
		  content:"\f1bb";
	  }

.cdc-icon-tools_04:before {
		  content:"\f1bc";
	  }

.cdc-icon-travel_01:before {
		  content:"\f1bd";
	  }

.cdc-icon-travel_02:before {
		  content:"\f1be";
	  }

.cdc-icon-tweezers:before {
		  content:"\f1bf";
	  }

.cdc-icon-video_01:before {
		  content:"\f1c0";
	  }

.cdc-icon-video_02:before {
		  content:"\f1c1";
	  }

.cdc-icon-video_03:before {
		  content:"\f1c2";
	  }

.cdc-icon-video_04:before {
		  content:"\f1c3";
	  }

.cdc-icon-weather_01:before {
		  content:"\f1c4";
	  }

.cdc-icon-weather_02:before {
		  content:"\f1c5";
	  }

.cdc-icon-weather_03:before {
		  content:"\f1c6";
	  }

.cdc-icon-weather_04:before {
		  content:"\f1c7";
	  }

.cdc-icon-weather_05:before {
		  content:"\f1c8";
	  }

.cdc-icon-weather_06:before {
		  content:"\f1c9";
	  }

.cdc-icon-weather_07:before {
		  content:"\f1ca";
	  }

.cdc-icon-weather_08:before {
		  content:"\f1cb";
	  }

.cdc-icon-weather_09:before {
		  content:"\f1cc";
	  }

.cdc-icon-weather_10:before {
		  content:"\f1cd";
	  }

.cdc-icon-weather_11:before {
		  content:"\f1ce";
	  }

.cdc-icon-cdc-tv:before {
		  content:"\f1cf";
	  }

.cdc-icon-fb:before {
		  content:"\f1d0";
	  }

.cdc-icon-flickr:before {
		  content:"\f1d1";
	  }

.cdc-icon-govd:before {
		  content:"\f1d2";
	  }

.cdc-icon-insta:before {
		  content:"\f1d3";
	  }

.cdc-icon-linkedin:before {
		  content:"\f1d4";
	  }

.cdc-icon-pinterest:before {
		  content:"\f1d5";
	  }

.cdc-icon-podcast:before {
		  content:"\f1d6";
	  }

.cdc-icon-rss:before {
		  content:"\f1d7";
	  }

.cdc-icon-syndication:before {
		  content:"\f1d8";
	  }

.cdc-icon-tumblr:before {
		  content:"\f1d9";
	  }

.cdc-icon-twitter:before {
		  content:"\f1da";
	  }

.cdc-icon-youtube:before {
		  content:"\f1db";
	  }

.cdc-icon-bookmark-round:before {
		  content:"\f1dc";
	  }

.cdc-icon-cdc-tv-round:before {
		  content:"\f1dd";
	  }

.cdc-icon-email-round:before {
		  content:"\f1de";
	  }

.cdc-icon-fb-round:before {
		  content:"\f1df";
	  }

.cdc-icon-flickr-round:before {
		  content:"\f1e0";
	  }

.cdc-icon-govd-round:before {
		  content:"\f1e1";
	  }

.cdc-icon-ig-round:before {
		  content:"\f1e2";
	  }

.cdc-icon-linkedin-round:before {
		  content:"\f1e3";
	  }

.cdc-icon-pinterest-round:before {
		  content:"\f1e4";
	  }

.cdc-icon-podcast-round:before {
		  content:"\f1e5";
	  }

.cdc-icon-rss-round:before {
		  content:"\f1e6";
	  }

.cdc-icon-syndication-round:before {
		  content:"\f1e7";
	  }

.cdc-icon-tumblr-round:before {
		  content:"\f1e8";
	  }

.cdc-icon-twitter-round:before {
		  content:"\f1e9";
	  }

.cdc-icon-youtube-round:before {
		  content:"\f1ea";
	  }

.cdc-icon-bookmark-white:before {
		  content:"\f1eb";
	  }

.cdc-icon-cdc-tv-white:before {
		  content:"\f1ec";
	  }

.cdc-icon-email-white:before {
		  content:"\f1ed";
	  }

.cdc-icon-fb-white:before {
		  content:"\f1ee";
	  }

.cdc-icon-flickr-white:before {
		  content:"\f1ef";
	  }

.cdc-icon-govd-white:before {
		  content:"\f1f0";
	  }

.cdc-icon-ig-white:before {
		  content:"\f1f1";
	  }

.cdc-icon-linkedin-white:before {
		  content:"\f1f2";
	  }

.cdc-icon-pinterest-white:before {
		  content:"\f1f3";
	  }

.cdc-icon-podcast-white:before {
		  content:"\f1f4";
	  }

.cdc-icon-rss-white:before {
		  content:"\f1f5";
	  }

.cdc-icon-syndication-white:before {
		  content:"\f1f6";
	  }

.cdc-icon-tumblr-white:before {
		  content:"\f1f7";
	  }

.cdc-icon-twitter-white:before {
		  content:"\f1f8";
	  }

.cdc-icon-youtube-white:before {
		  content:"\f1f9";
	  }

.cdc-icon-epub:before {
		  content:"\f1fa";
	  }

.cdc-icon-excel:before {
		  content:"\f1fb";
	  }

.cdc-icon-external:before {
		  content:"\f1fc";
	  }

.cdc-icon-image:before {
		  content:"\f1fd";
	  }

.cdc-icon-media:before {
		  content:"\f1fe";
	  }

.cdc-icon-pdf:before {
		  content:"\f1ff";
	  }

.cdc-icon-ppt:before {
		  content:"\f200";
	  }

.cdc-icon-sas:before {
		  content:"\f201";
	  }

.cdc-icon-stats:before {
		  content:"\f202";
	  }

.cdc-icon-txt:before {
		  content:"\f203";
	  }

.cdc-icon-word:before {
		  content:"\f204";
	  }

.cdc-icon-zip:before {
		  content:"\f205";
	  }

.cdc-icon-arrow-down:before {
		  content:"\f206";
	  }

.cdc-icon-cdcsearch:before {
		  content:"\f207";
	  }

.cdc-icon-close-circle:before {
		  content:"\f208";
	  }

.cdc-icon-minus:before {
		  content:"\f209";
	  }

.cdc-icon-next:before {
		  content:"\f20a";
	  }

.cdc-icon-play-circle-outline:before {
		  content:"\f20b";
	  }

.cdc-icon-plus:before {
		  content:"\f20c";
	  }

.cdc-icon-prev:before {
		  content:"\f20d";
	  }

.cdc-icon-resize:before {
		  content:"\f20e";
	  }

.cdc-icon-cdc-play:before {
		  content:"\f20f";
	  }

.cdc-icon-pause-circle-outline:before {
		  content:"\f210";
	  }

.cdc-icon-pause-circle:before {
		  content:"\f211";
	  }

.cdc-icon-pause-octagon-outline:before {
		  content:"\f212";
	  }

.cdc-icon-pause-octagon:before {
		  content:"\f213";
	  }

.cdc-icon-pause:before {
		  content:"\f214";
	  }

.cdc-icon-repeat-off:before {
		  content:"\f215";
	  }

.cdc-icon-repeat-once:before {
		  content:"\f216";
	  }

.cdc-icon-repeat:before {
		  content:"\f217";
	  }

.cdc-icon-replay:before {
		  content:"\f218";
	  }

.cdc-icon-shuffle-disabled:before {
		  content:"\f219";
	  }

.cdc-icon-shuffle-variant:before {
		  content:"\f21a";
	  }

.cdc-icon-shuffle:before {
		  content:"\f21b";
	  }

.cdc-icon-skip-backward:before {
		  content:"\f21c";
	  }

.cdc-icon-skip-forward:before {
		  content:"\f21d";
	  }

.cdc-icon-skip-next-circle-outline:before {
		  content:"\f21e";
	  }

.cdc-icon-skip-next-circle:before {
		  content:"\f21f";
	  }

.cdc-icon-skip-next:before {
		  content:"\f220";
	  }

.cdc-icon-skip-previous-circle-outline:before {
		  content:"\f221";
	  }

.cdc-icon-skip-previous-circle:before {
		  content:"\f222";
	  }

.cdc-icon-skip-previous:before {
		  content:"\f223";
	  }

.cdc-icon-stop:before {
		  content:"\f224";
	  }

.cdc-icon-surround-sound-2-0:before {
		  content:"\f225";
	  }

.cdc-icon-surround-sound-3-1:before {
		  content:"\f226";
	  }

.cdc-icon-surround-sound-5-1:before {
		  content:"\f227";
	  }

.cdc-icon-surround-sound-7-1:before {
		  content:"\f228";
	  }

.cdc-icon-surround-sound:before {
		  content:"\f229";
	  }

.cdc-icon-volume-high:before {
		  content:"\f22a";
	  }

.cdc-icon-volume-low:before {
		  content:"\f22b";
	  }

.cdc-icon-volume-medium:before {
		  content:"\f22c";
	  }

.cdc-icon-volume-minus:before {
		  content:"\f22d";
	  }

.cdc-icon-volume-mute:before {
		  content:"\f22e";
	  }

.cdc-icon-volume-off:before {
		  content:"\f22f";
	  }

.cdc-icon-volume-plus:before {
		  content:"\f230";
	  }

.cdc-icon-volume:before {
		  content:"\f231";
	  }

.cdc-icon-home:before {
		  content:"\f232";
	  }

.cdc-icon-magnify:before {
		  content:"\f233";
	  }

.cdc-icon-email:before {
		  content:"\f234";
	  }

.cdc-icon-phone:before {
		  content:"\f235";
	  }

.cdc-icon-settings:before {
		  content:"\f236";
	  }

.cdc-icon-close:before {
		  content:"\f237";
	  }

.cdc-icon-accessible-icon:before {
		  content:"\f238";
	  }

.cdc-icon-algolia:before {
		  content:"\f239";
	  }

.cdc-icon-hubspot:before {
		  content:"\f23a";
	  }

.cdc-icon-microsoft:before {
		  content:"\f23b";
	  }

.cdc-icon-windows:before {
		  content:"\f23c";
	  }

.cdc-icon-square:before {
		  content:"\f23d";
	  }

.cdc-icon-address-book-light:before {
		  content:"\f23e";
	  }

.cdc-icon-address-card-light:before {
		  content:"\f23f";
	  }

.cdc-icon-allergies-light:before {
		  content:"\f240";
	  }

.cdc-icon-ambulance-light:before {
		  content:"\f241";
	  }

.cdc-icon-american-sign-language-interpreting-light:before {
		  content:"\f242";
	  }

.cdc-icon-analytics-light:before {
		  content:"\f243";
	  }

.cdc-icon-apple-alt-light:before {
		  content:"\f244";
	  }

.cdc-icon-archive-light:before {
		  content:"\f245";
	  }

.cdc-icon-arrow-alt-circle-down-light:before {
		  content:"\f246";
	  }

.cdc-icon-arrow-alt-circle-left-light:before {
		  content:"\f247";
	  }

.cdc-icon-arrow-alt-circle-right-light:before {
		  content:"\f248";
	  }

.cdc-icon-arrow-alt-circle-up-light:before {
		  content:"\f249";
	  }

.cdc-icon-atlas-light:before {
		  content:"\f24a";
	  }

.cdc-icon-baby-carriage-light:before {
		  content:"\f24b";
	  }

.cdc-icon-baby-light:before {
		  content:"\f24c";
	  }

.cdc-icon-bacteria-light:before {
		  content:"\f24d";
	  }

.cdc-icon-bacterium-light:before {
		  content:"\f24e";
	  }

.cdc-icon-balance-scale-light:before {
		  content:"\f24f";
	  }

.cdc-icon-ballot-check-light:before {
		  content:"\f250";
	  }

.cdc-icon-ballot-light:before {
		  content:"\f251";
	  }

.cdc-icon-ban-light:before {
		  content:"\f252";
	  }

.cdc-icon-band-aid-light:before {
		  content:"\f253";
	  }

.cdc-icon-bed-light:before {
		  content:"\f254";
	  }

.cdc-icon-bicycle-light:before {
		  content:"\f255";
	  }

.cdc-icon-biking-light:before {
		  content:"\f256";
	  }

.cdc-icon-blind-light:before {
		  content:"\f257";
	  }

.cdc-icon-bolt-light:before {
		  content:"\f258";
	  }

.cdc-icon-book-alt-light:before {
		  content:"\f259";
	  }

.cdc-icon-book-light:before {
		  content:"\f25a";
	  }

.cdc-icon-book-medical-light:before {
		  content:"\f25b";
	  }

.cdc-icon-book-open-light:before {
		  content:"\f25c";
	  }

.cdc-icon-book-reader-light:before {
		  content:"\f25d";
	  }

.cdc-icon-book-user-light:before {
		  content:"\f25e";
	  }

.cdc-icon-books-medical-light:before {
		  content:"\f25f";
	  }

.cdc-icon-box-tissue-light:before {
		  content:"\f260";
	  }

.cdc-icon-bread-loaf-light:before {
		  content:"\f261";
	  }

.cdc-icon-briefcase-light:before {
		  content:"\f262";
	  }

.cdc-icon-briefcase-medical-light:before {
		  content:"\f263";
	  }

.cdc-icon-browser-light:before {
		  content:"\f264";
	  }

.cdc-icon-building-light:before {
		  content:"\f265";
	  }

.cdc-icon-bullseye-pointer-light:before {
		  content:"\f266";
	  }

.cdc-icon-burger-soda-light:before {
		  content:"\f267";
	  }

.cdc-icon-bus-light:before {
		  content:"\f268";
	  }

.cdc-icon-calendar-alt-light:before {
		  content:"\f269";
	  }

.cdc-icon-calendar-check-light:before {
		  content:"\f26a";
	  }

.cdc-icon-calendar-day-light:before {
		  content:"\f26b";
	  }

.cdc-icon-calendar-edit-light:before {
		  content:"\f26c";
	  }

.cdc-icon-calendar-exclamation-light:before {
		  content:"\f26d";
	  }

.cdc-icon-calendar-light:before {
		  content:"\f26e";
	  }

.cdc-icon-calendar-plus-light:before {
		  content:"\f26f";
	  }

.cdc-icon-calendar-star-light:before {
		  content:"\f270";
	  }

.cdc-icon-capsules-light:before {
		  content:"\f271";
	  }

.cdc-icon-car-bus-light:before {
		  content:"\f272";
	  }

.cdc-icon-car-side-light:before {
		  content:"\f273";
	  }

.cdc-icon-carrot-light:before {
		  content:"\f274";
	  }

.cdc-icon-chalkboard-teacher-light:before {
		  content:"\f275";
	  }

.cdc-icon-chart-area-light:before {
		  content:"\f276";
	  }

.cdc-icon-chart-bar-light:before {
		  content:"\f277";
	  }

.cdc-icon-chart-line-light:before {
		  content:"\f278";
	  }

.cdc-icon-chart-pie-light:before {
		  content:"\f279";
	  }

.cdc-icon-check-circle-light:before {
		  content:"\f27a";
	  }

.cdc-icon-check-light:before {
		  content:"\f27b";
	  }

.cdc-icon-check-square-light:before {
		  content:"\f27c";
	  }

.cdc-icon-cheese-swiss-light:before {
		  content:"\f27d";
	  }

.cdc-icon-cheeseburger-light:before {
		  content:"\f27e";
	  }

.cdc-icon-chevron-circle-down-light:before {
		  content:"\f27f";
	  }

.cdc-icon-chevron-circle-left-light:before {
		  content:"\f280";
	  }

.cdc-icon-chevron-circle-right-light:before {
		  content:"\f281";
	  }

.cdc-icon-chevron-circle-up-light:before {
		  content:"\f282";
	  }

.cdc-icon-chevron-double-right-light:before {
		  content:"\f283";
	  }

.cdc-icon-chevron-right-light:before {
		  content:"\f284";
	  }

.cdc-icon-child-light:before {
		  content:"\f285";
	  }

.cdc-icon-city-light:before {
		  content:"\f286";
	  }

.cdc-icon-clinic-medical-light:before {
		  content:"\f287";
	  }

.cdc-icon-clipboard-check-light:before {
		  content:"\f288";
	  }

.cdc-icon-clipboard-light:before {
		  content:"\f289";
	  }

.cdc-icon-clipboard-list-check-light:before {
		  content:"\f28a";
	  }

.cdc-icon-clipboard-list-light:before {
		  content:"\f28b";
	  }

.cdc-icon-clipboard-prescription-light:before {
		  content:"\f28c";
	  }

.cdc-icon-clipboard-user-light:before {
		  content:"\f28d";
	  }

.cdc-icon-cloud-light:before {
		  content:"\f28e";
	  }

.cdc-icon-clouds-sun-light:before {
		  content:"\f28f";
	  }

.cdc-icon-cocktail-light:before {
		  content:"\f290";
	  }

.cdc-icon-code-light:before {
		  content:"\f291";
	  }

.cdc-icon-coffee-togo-light:before {
		  content:"\f292";
	  }

.cdc-icon-cog-light:before {
		  content:"\f293";
	  }

.cdc-icon-cogs-light:before {
		  content:"\f294";
	  }

.cdc-icon-comment-alt-light:before {
		  content:"\f295";
	  }

.cdc-icon-comment-alt-lines-light:before {
		  content:"\f296";
	  }

.cdc-icon-comment-alt-medical-light:before {
		  content:"\f297";
	  }

.cdc-icon-comment-light:before {
		  content:"\f298";
	  }

.cdc-icon-comment-lines-light:before {
		  content:"\f299";
	  }

.cdc-icon-comment-medical-light:before {
		  content:"\f29a";
	  }

.cdc-icon-comments-alt-light:before {
		  content:"\f29b";
	  }

.cdc-icon-comments-light:before {
		  content:"\f29c";
	  }

.cdc-icon-conveyor-belt-light:before {
		  content:"\f29d";
	  }

.cdc-icon-copy-light:before {
		  content:"\f29e";
	  }

.cdc-icon-couch-light:before {
		  content:"\f29f";
	  }

.cdc-icon-cow-light:before {
		  content:"\f2a0";
	  }

.cdc-icon-credit-card-light:before {
		  content:"\f2a1";
	  }

.cdc-icon-database-light:before {
		  content:"\f2a2";
	  }

.cdc-icon-deaf-light:before {
		  content:"\f2a3";
	  }

.cdc-icon-desktop-alt-light:before {
		  content:"\f2a4";
	  }

.cdc-icon-desktop-light:before {
		  content:"\f2a5";
	  }

.cdc-icon-diagnoses-light:before {
		  content:"\f2a6";
	  }

.cdc-icon-disease-light:before {
		  content:"\f2a7";
	  }

.cdc-icon-dna-light:before {
		  content:"\f2a8";
	  }

.cdc-icon-dog-light:before {
		  content:"\f2a9";
	  }

.cdc-icon-dolly-flatbed-alt-light:before {
		  content:"\f2aa";
	  }

.cdc-icon-door-closed-light:before {
		  content:"\f2ab";
	  }

.cdc-icon-door-open-light:before {
		  content:"\f2ac";
	  }

.cdc-icon-download-light:before {
		  content:"\f2ad";
	  }

.cdc-icon-drumstick-light:before {
		  content:"\f2ae";
	  }

.cdc-icon-dumbbell-light:before {
		  content:"\f2af";
	  }

.cdc-icon-dumpster-light:before {
		  content:"\f2b0";
	  }

.cdc-icon-ear-light:before {
		  content:"\f2b1";
	  }

.cdc-icon-envelope-light:before {
		  content:"\f2b2";
	  }

.cdc-icon-envelope-open-text-light:before {
		  content:"\f2b3";
	  }

.cdc-icon-exclamation-circle-light:before {
		  content:"\f2b4";
	  }

.cdc-icon-exclamation-square-light:before {
		  content:"\f2b5";
	  }

.cdc-icon-exclamation-triangle-light:before {
		  content:"\f2b6";
	  }

.cdc-icon-eye-dropper-light:before {
		  content:"\f2b7";
	  }

.cdc-icon-eye-light:before {
		  content:"\f2b8";
	  }

.cdc-icon-farm-light:before {
		  content:"\f2b9";
	  }

.cdc-icon-female-light:before {
		  content:"\f2ba";
	  }

.cdc-icon-file-alt-light:before {
		  content:"\f2bb";
	  }

.cdc-icon-file-archive-light:before {
		  content:"\f2bc";
	  }

.cdc-icon-file-audio-light:before {
		  content:"\f2bd";
	  }

.cdc-icon-file-certificate-light:before {
		  content:"\f2be";
	  }

.cdc-icon-file-chart-line-light:before {
		  content:"\f2bf";
	  }

.cdc-icon-file-medical-alt-light:before {
		  content:"\f2c0";
	  }

.cdc-icon-file-medical-light:before {
		  content:"\f2c1";
	  }

.cdc-icon-file-prescription-light:before {
		  content:"\f2c2";
	  }

.cdc-icon-files-medical-light:before {
		  content:"\f2c3";
	  }

.cdc-icon-first-aid-light:before {
		  content:"\f2c4";
	  }

.cdc-icon-flag-alt-light:before {
		  content:"\f2c5";
	  }

.cdc-icon-flag-light:before {
		  content:"\f2c6";
	  }

.cdc-icon-flask-light:before {
		  content:"\f2c7";
	  }

.cdc-icon-flower-daffodil-light:before {
		  content:"\f2c8";
	  }

.cdc-icon-folder-plus-light:before {
		  content:"\f2c9";
	  }

.cdc-icon-football-ball-light:before {
		  content:"\f2ca";
	  }

.cdc-icon-forklift-light:before {
		  content:"\f2cb";
	  }

.cdc-icon-frown-light:before {
		  content:"\f2cc";
	  }

.cdc-icon-gas-pump-light:before {
		  content:"\f2cd";
	  }

.cdc-icon-globe-africa-light:before {
		  content:"\f2ce";
	  }

.cdc-icon-globe-americas-light:before {
		  content:"\f2cf";
	  }

.cdc-icon-globe-asia-light:before {
		  content:"\f2d0";
	  }

.cdc-icon-globe-europe-light:before {
		  content:"\f2d1";
	  }

.cdc-icon-globe-light:before {
		  content:"\f2d2";
	  }

.cdc-icon-graduation-cap-light:before {
		  content:"\f2d3";
	  }

.cdc-icon-grin-light:before {
		  content:"\f2d4";
	  }

.cdc-icon-h-square-light:before {
		  content:"\f2d5";
	  }

.cdc-icon-hand-holding-heart-light:before {
		  content:"\f2d6";
	  }

.cdc-icon-hand-holding-medical-light:before {
		  content:"\f2d7";
	  }

.cdc-icon-hand-paper-light:before {
		  content:"\f2d8";
	  }

.cdc-icon-hand-point-right-light:before {
		  content:"\f2d9";
	  }

.cdc-icon-hand-sparkles-light:before {
		  content:"\f2da";
	  }

.cdc-icon-hands-helping-light:before {
		  content:"\f2db";
	  }

.cdc-icon-hands-wash-light:before {
		  content:"\f2dc";
	  }

.cdc-icon-handshake-slash-light:before {
		  content:"\f2dd";
	  }

.cdc-icon-head-side-cough-light:before {
		  content:"\f2de";
	  }

.cdc-icon-head-side-cough-slash-light:before {
		  content:"\f2df";
	  }

.cdc-icon-head-side-mask-light:before {
		  content:"\f2e0";
	  }

.cdc-icon-head-side-medical-light:before {
		  content:"\f2e1";
	  }

.cdc-icon-headset-light:before {
		  content:"\f2e2";
	  }

.cdc-icon-heart-rate-light:before {
		  content:"\f2e3";
	  }

.cdc-icon-heartbeat-light:before {
		  content:"\f2e4";
	  }

.cdc-icon-hiking-light:before {
		  content:"\f2e5";
	  }

.cdc-icon-home-lg-light:before {
		  content:"\f2e6";
	  }

.cdc-icon-hospital-alt-light:before {
		  content:"\f2e7";
	  }

.cdc-icon-hospital-light:before {
		  content:"\f2e8";
	  }

.cdc-icon-hospital-symbol-light:before {
		  content:"\f2e9";
	  }

.cdc-icon-hospital-user-light:before {
		  content:"\f2ea";
	  }

.cdc-icon-hotel-light:before {
		  content:"\f2eb";
	  }

.cdc-icon-house-leave-light:before {
		  content:"\f2ec";
	  }

.cdc-icon-house-return-light:before {
		  content:"\f2ed";
	  }

.cdc-icon-house-user-light:before {
		  content:"\f2ee";
	  }

.cdc-icon-ice-cream-light:before {
		  content:"\f2ef";
	  }

.cdc-icon-id-badge-light:before {
		  content:"\f2f0";
	  }

.cdc-icon-id-card-light:before {
		  content:"\f2f1";
	  }

.cdc-icon-inbox-light:before {
		  content:"\f2f2";
	  }

.cdc-icon-info-circle-light:before {
		  content:"\f2f3";
	  }

.cdc-icon-info-light:before {
		  content:"\f2f4";
	  }

.cdc-icon-info-square-light:before {
		  content:"\f2f5";
	  }

.cdc-icon-inhaler-light:before {
		  content:"\f2f6";
	  }

.cdc-icon-key-light:before {
		  content:"\f2f7";
	  }

.cdc-icon-keyboard-light:before {
		  content:"\f2f8";
	  }

.cdc-icon-keynote-light:before {
		  content:"\f2f9";
	  }

.cdc-icon-landmark-alt-light:before {
		  content:"\f2fa";
	  }

.cdc-icon-landmark-light:before {
		  content:"\f2fb";
	  }

.cdc-icon-laptop-house-light:before {
		  content:"\f2fc";
	  }

.cdc-icon-laptop-light:before {
		  content:"\f2fd";
	  }

.cdc-icon-laptop-medical-light:before {
		  content:"\f2fe";
	  }

.cdc-icon-leaf-light:before {
		  content:"\f2ff";
	  }

.cdc-icon-leaf-oak-light:before {
		  content:"\f300";
	  }

.cdc-icon-lightbulb-light:before {
		  content:"\f301";
	  }

.cdc-icon-link-light:before {
		  content:"\f302";
	  }

.cdc-icon-lock-alt-light:before {
		  content:"\f303";
	  }

.cdc-icon-lock-open-alt-light:before {
		  content:"\f304";
	  }

.cdc-icon-luggage-cart-light:before {
		  content:"\f305";
	  }

.cdc-icon-lungs-light:before {
		  content:"\f306";
	  }

.cdc-icon-lungs-virus-light:before {
		  content:"\f307";
	  }

.cdc-icon-mail-bulk-light:before {
		  content:"\f308";
	  }

.cdc-icon-male-light:before {
		  content:"\f309";
	  }

.cdc-icon-map-light:before {
		  content:"\f30a";
	  }

.cdc-icon-map-marker-alt-slash-light:before {
		  content:"\f30b";
	  }

.cdc-icon-medkit-light:before {
		  content:"\f30c";
	  }

.cdc-icon-microphone-light:before {
		  content:"\f30d";
	  }

.cdc-icon-microscope-light:before {
		  content:"\f30e";
	  }

.cdc-icon-mobile-alt-light:before {
		  content:"\f30f";
	  }

.cdc-icon-mobile-light:before {
		  content:"\f310";
	  }

.cdc-icon-monitor-heart-rate-light:before {
		  content:"\f311";
	  }

.cdc-icon-moon-light:before {
		  content:"\f312";
	  }

.cdc-icon-mortar-pestle-light:before {
		  content:"\f313";
	  }

.cdc-icon-mouse-pointer-light:before {
		  content:"\f314";
	  }

.cdc-icon-network-wired-light:before {
		  content:"\f315";
	  }

.cdc-icon-newspaper-light:before {
		  content:"\f316";
	  }

.cdc-icon-notes-medical-light:before {
		  content:"\f317";
	  }

.cdc-icon-passport-light:before {
		  content:"\f318";
	  }

.cdc-icon-paw-light:before {
		  content:"\f319";
	  }

.cdc-icon-people-arrows-light:before {
		  content:"\f31a";
	  }

.cdc-icon-person-sign-light:before {
		  content:"\f31b";
	  }

.cdc-icon-phone-office-light:before {
		  content:"\f31c";
	  }

.cdc-icon-pills-light:before {
		  content:"\f31d";
	  }

.cdc-icon-place-of-worship-light:before {
		  content:"\f31e";
	  }

.cdc-icon-plane-arrival-light:before {
		  content:"\f31f";
	  }

.cdc-icon-plane-departure-light:before {
		  content:"\f320";
	  }

.cdc-icon-plane-light:before {
		  content:"\f321";
	  }

.cdc-icon-plane-slash-light:before {
		  content:"\f322";
	  }

.cdc-icon-play-circle-light:before {
		  content:"\f323";
	  }

.cdc-icon-play-light:before {
		  content:"\f324";
	  }

.cdc-icon-poll-people-light:before {
		  content:"\f325";
	  }

.cdc-icon-popcorn-light:before {
		  content:"\f326";
	  }

.cdc-icon-portrait-light:before {
		  content:"\f327";
	  }

.cdc-icon-prescription-bottle-alt-light:before {
		  content:"\f328";
	  }

.cdc-icon-prescription-bottle-light:before {
		  content:"\f329";
	  }

.cdc-icon-prescription-light:before {
		  content:"\f32a";
	  }

.cdc-icon-presentation-light:before {
		  content:"\f32b";
	  }

.cdc-icon-procedures-light:before {
		  content:"\f32c";
	  }

.cdc-icon-pump-medical-light:before {
		  content:"\f32d";
	  }

.cdc-icon-pump-soap-light:before {
		  content:"\f32e";
	  }

.cdc-icon-pumpkin-light:before {
		  content:"\f32f";
	  }

.cdc-icon-question-circle-light:before {
		  content:"\f330";
	  }

.cdc-icon-question-light:before {
		  content:"\f331";
	  }

.cdc-icon-question-square-light:before {
		  content:"\f332";
	  }

.cdc-icon-ribbon-light:before {
		  content:"\f333";
	  }

.cdc-icon-running-light:before {
		  content:"\f334";
	  }

.cdc-icon-school-light:before {
		  content:"\f335";
	  }

.cdc-icon-search-light:before {
		  content:"\f336";
	  }

.cdc-icon-share-alt-light:before {
		  content:"\f337";
	  }

.cdc-icon-share-light:before {
		  content:"\f338";
	  }

.cdc-icon-ship-light:before {
		  content:"\f339";
	  }

.cdc-icon-shipping-fast-light:before {
		  content:"\f33a";
	  }

.cdc-icon-shopping-cart-light:before {
		  content:"\f33b";
	  }

.cdc-icon-shower-light:before {
		  content:"\f33c";
	  }

.cdc-icon-shuttle-van-light:before {
		  content:"\f33d";
	  }

.cdc-icon-sign-language-light:before {
		  content:"\f33e";
	  }

.cdc-icon-sink-light:before {
		  content:"\f33f";
	  }

.cdc-icon-sitemap-light:before {
		  content:"\f340";
	  }

.cdc-icon-soap-light:before {
		  content:"\f341";
	  }

.cdc-icon-spray-can-light:before {
		  content:"\f342";
	  }

.cdc-icon-star-light:before {
		  content:"\f343";
	  }

.cdc-icon-stethoscope-light:before {
		  content:"\f344";
	  }

.cdc-icon-stomach-light:before {
		  content:"\f345";
	  }

.cdc-icon-stopwatch-light:before {
		  content:"\f346";
	  }

.cdc-icon-store-alt-slash-light:before {
		  content:"\f347";
	  }

.cdc-icon-store-slash-light:before {
		  content:"\f348";
	  }

.cdc-icon-stretcher-light:before {
		  content:"\f349";
	  }

.cdc-icon-subway-light:before {
		  content:"\f34a";
	  }

.cdc-icon-suitcase-light:before {
		  content:"\f34b";
	  }

.cdc-icon-sun-light:before {
		  content:"\f34c";
	  }

.cdc-icon-swimmer-light:before {
		  content:"\f34d";
	  }

.cdc-icon-swimming-pool-light:before {
		  content:"\f34e";
	  }

.cdc-icon-sync-alt-light:before {
		  content:"\f34f";
	  }

.cdc-icon-syringe-light:before {
		  content:"\f350";
	  }

.cdc-icon-table-light:before {
		  content:"\f351";
	  }

.cdc-icon-tablet-alt-light:before {
		  content:"\f352";
	  }

.cdc-icon-tablets-light:before {
		  content:"\f353";
	  }

.cdc-icon-taxi-light:before {
		  content:"\f354";
	  }

.cdc-icon-temperature-high-light:before {
		  content:"\f355";
	  }

.cdc-icon-temperature-low-light:before {
		  content:"\f356";
	  }

.cdc-icon-thumbs-down-light:before {
		  content:"\f357";
	  }

.cdc-icon-thumbs-up-light:before {
		  content:"\f358";
	  }

.cdc-icon-times-circle-light:before {
		  content:"\f359";
	  }

.cdc-icon-times-light:before {
		  content:"\f35a";
	  }

.cdc-icon-tint-light:before {
		  content:"\f35b";
	  }

.cdc-icon-tint-slash-light:before {
		  content:"\f35c";
	  }

.cdc-icon-toolbox-light:before {
		  content:"\f35d";
	  }

.cdc-icon-tools-light:before {
		  content:"\f35e";
	  }

.cdc-icon-tooth-light:before {
		  content:"\f35f";
	  }

.cdc-icon-train-light:before {
		  content:"\f360";
	  }

.cdc-icon-trash-light:before {
		  content:"\f361";
	  }

.cdc-icon-tshirt-light:before {
		  content:"\f362";
	  }

.cdc-icon-umbrella-beach-light:before {
		  content:"\f363";
	  }

.cdc-icon-universal-access-light:before {
		  content:"\f364";
	  }

.cdc-icon-user-chart-light:before {
		  content:"\f365";
	  }

.cdc-icon-user-clock-light:before {
		  content:"\f366";
	  }

.cdc-icon-user-cog-light:before {
		  content:"\f367";
	  }

.cdc-icon-user-friends-light:before {
		  content:"\f368";
	  }

.cdc-icon-user-graduate-light:before {
		  content:"\f369";
	  }

.cdc-icon-user-hard-hat-light:before {
		  content:"\f36a";
	  }

.cdc-icon-user-injured-light:before {
		  content:"\f36b";
	  }

.cdc-icon-user-light:before {
		  content:"\f36c";
	  }

.cdc-icon-user-md-chat-light:before {
		  content:"\f36d";
	  }

.cdc-icon-user-md-light:before {
		  content:"\f36e";
	  }

.cdc-icon-user-nurse-light:before {
		  content:"\f36f";
	  }

.cdc-icon-users-class-light:before {
		  content:"\f370";
	  }

.cdc-icon-users-light:before {
		  content:"\f371";
	  }

.cdc-icon-users-medical-light:before {
		  content:"\f372";
	  }

.cdc-icon-users-slash-light:before {
		  content:"\f373";
	  }

.cdc-icon-venus-mars-light:before {
		  content:"\f374";
	  }

.cdc-icon-vial-light:before {
		  content:"\f375";
	  }

.cdc-icon-vials-light:before {
		  content:"\f376";
	  }

.cdc-icon-virus-light:before {
		  content:"\f377";
	  }

.cdc-icon-virus-slash-light:before {
		  content:"\f378";
	  }

.cdc-icon-viruses-light:before {
		  content:"\f379";
	  }

.cdc-icon-walking-light:before {
		  content:"\f37a";
	  }

.cdc-icon-washer-light:before {
		  content:"\f37b";
	  }

.cdc-icon-water-light:before {
		  content:"\f37c";
	  }

.cdc-icon-weight-light:before {
		  content:"\f37d";
	  }

.cdc-icon-wheelchair-light:before {
		  content:"\f37e";
	  }

.cdc-icon-window-close-light:before {
		  content:"\f37f";
	  }

.cdc-icon-x-ray-light:before {
		  content:"\f380";
	  }

.cdc-icon-address-book:before {
		  content:"\f381";
	  }

.cdc-icon-address-card:before {
		  content:"\f382";
	  }

.cdc-icon-allergies:before {
		  content:"\f383";
	  }

.cdc-icon-ambulance:before {
		  content:"\f384";
	  }

.cdc-icon-american-sign-language-interpreting:before {
		  content:"\f385";
	  }

.cdc-icon-analytics:before {
		  content:"\f386";
	  }

.cdc-icon-apple-alt:before {
		  content:"\f387";
	  }

.cdc-icon-archive:before {
		  content:"\f388";
	  }

.cdc-icon-arrow-alt-circle-down:before {
		  content:"\f389";
	  }

.cdc-icon-arrow-alt-circle-left:before {
		  content:"\f38a";
	  }

.cdc-icon-arrow-alt-circle-right:before {
		  content:"\f38b";
	  }

.cdc-icon-arrow-alt-circle-up:before {
		  content:"\f38c";
	  }

.cdc-icon-atlas:before {
		  content:"\f38d";
	  }

.cdc-icon-baby-carriage:before {
		  content:"\f38e";
	  }

.cdc-icon-baby:before {
		  content:"\f38f";
	  }

.cdc-icon-bacteria:before {
		  content:"\f390";
	  }

.cdc-icon-bacterium:before {
		  content:"\f391";
	  }

.cdc-icon-balance-scale:before {
		  content:"\f392";
	  }

.cdc-icon-ballot-check:before {
		  content:"\f393";
	  }

.cdc-icon-ballot:before {
		  content:"\f394";
	  }

.cdc-icon-ban:before {
		  content:"\f395";
	  }

.cdc-icon-band-aid:before {
		  content:"\f396";
	  }

.cdc-icon-bed:before {
		  content:"\f397";
	  }

.cdc-icon-bicycle:before {
		  content:"\f398";
	  }

.cdc-icon-biking:before {
		  content:"\f399";
	  }

.cdc-icon-blind:before {
		  content:"\f39a";
	  }

.cdc-icon-bolt:before {
		  content:"\f39b";
	  }

.cdc-icon-book-alt:before {
		  content:"\f39c";
	  }

.cdc-icon-book-medical:before {
		  content:"\f39d";
	  }

.cdc-icon-book-open:before {
		  content:"\f39e";
	  }

.cdc-icon-book-reader:before {
		  content:"\f39f";
	  }

.cdc-icon-book-user:before {
		  content:"\f3a0";
	  }

.cdc-icon-book:before {
		  content:"\f3a1";
	  }

.cdc-icon-books-medical:before {
		  content:"\f3a2";
	  }

.cdc-icon-box-tissue:before {
		  content:"\f3a3";
	  }

.cdc-icon-bread-loaf:before {
		  content:"\f3a4";
	  }

.cdc-icon-briefcase-medical:before {
		  content:"\f3a5";
	  }

.cdc-icon-briefcase:before {
		  content:"\f3a6";
	  }

.cdc-icon-browser:before {
		  content:"\f3a7";
	  }

.cdc-icon-building:before {
		  content:"\f3a8";
	  }

.cdc-icon-bullseye-pointer:before {
		  content:"\f3a9";
	  }

.cdc-icon-burger-soda:before {
		  content:"\f3aa";
	  }

.cdc-icon-bus:before {
		  content:"\f3ab";
	  }

.cdc-icon-calendar-alt:before {
		  content:"\f3ac";
	  }

.cdc-icon-calendar-check:before {
		  content:"\f3ad";
	  }

.cdc-icon-calendar-day:before {
		  content:"\f3ae";
	  }

.cdc-icon-calendar-edit:before {
		  content:"\f3af";
	  }

.cdc-icon-calendar-exclamation:before {
		  content:"\f3b0";
	  }

.cdc-icon-calendar-plus:before {
		  content:"\f3b1";
	  }

.cdc-icon-calendar-star:before {
		  content:"\f3b2";
	  }

.cdc-icon-calendar:before {
		  content:"\f3b3";
	  }

.cdc-icon-capsules:before {
		  content:"\f3b4";
	  }

.cdc-icon-car-bus:before {
		  content:"\f3b5";
	  }

.cdc-icon-car-side:before {
		  content:"\f3b6";
	  }

.cdc-icon-carrot:before {
		  content:"\f3b7";
	  }

.cdc-icon-chalkboard-teacher:before {
		  content:"\f3b8";
	  }

.cdc-icon-chart-area:before {
		  content:"\f3b9";
	  }

.cdc-icon-chart-bar:before {
		  content:"\f3ba";
	  }

.cdc-icon-chart-line:before {
		  content:"\f3bb";
	  }

.cdc-icon-chart-pie:before {
		  content:"\f3bc";
	  }

.cdc-icon-check-circle:before {
		  content:"\f3bd";
	  }

.cdc-icon-check-square:before {
		  content:"\f3be";
	  }

.cdc-icon-check:before {
		  content:"\f3bf";
	  }

.cdc-icon-cheese-swiss:before {
		  content:"\f3c0";
	  }

.cdc-icon-cheeseburger:before {
		  content:"\f3c1";
	  }

.cdc-icon-chevron-circle-down:before {
		  content:"\f3c2";
	  }

.cdc-icon-chevron-circle-left:before {
		  content:"\f3c3";
	  }

.cdc-icon-chevron-circle-right:before {
		  content:"\f3c4";
	  }

.cdc-icon-chevron-circle-up:before {
		  content:"\f3c5";
	  }

.cdc-icon-chevron-double-right:before {
		  content:"\f3c6";
	  }

.cdc-icon-chevron-right:before {
		  content:"\f3c7";
	  }

.cdc-icon-child:before {
		  content:"\f3c8";
	  }

.cdc-icon-city:before {
		  content:"\f3c9";
	  }

.cdc-icon-clinic-medical:before {
		  content:"\f3ca";
	  }

.cdc-icon-clipboard-check:before {
		  content:"\f3cb";
	  }

.cdc-icon-clipboard-list-check:before {
		  content:"\f3cc";
	  }

.cdc-icon-clipboard-list:before {
		  content:"\f3cd";
	  }

.cdc-icon-clipboard-prescription:before {
		  content:"\f3ce";
	  }

.cdc-icon-clipboard-user:before {
		  content:"\f3cf";
	  }

.cdc-icon-clipboard:before {
		  content:"\f3d0";
	  }

.cdc-icon-cloud:before {
		  content:"\f3d1";
	  }

.cdc-icon-clouds-sun:before {
		  content:"\f3d2";
	  }

.cdc-icon-cocktail:before {
		  content:"\f3d3";
	  }

.cdc-icon-code:before {
		  content:"\f3d4";
	  }

.cdc-icon-coffee-togo:before {
		  content:"\f3d5";
	  }

.cdc-icon-cog:before {
		  content:"\f3d6";
	  }

.cdc-icon-cogs:before {
		  content:"\f3d7";
	  }

.cdc-icon-comment-alt-lines:before {
		  content:"\f3d8";
	  }

.cdc-icon-comment-alt-medical:before {
		  content:"\f3d9";
	  }

.cdc-icon-comment-alt:before {
		  content:"\f3da";
	  }

.cdc-icon-comment-lines:before {
		  content:"\f3db";
	  }

.cdc-icon-comment-medical:before {
		  content:"\f3dc";
	  }

.cdc-icon-comment:before {
		  content:"\f3dd";
	  }

.cdc-icon-comments-alt:before {
		  content:"\f3de";
	  }

.cdc-icon-comments:before {
		  content:"\f3df";
	  }

.cdc-icon-conveyor-belt:before {
		  content:"\f3e0";
	  }

.cdc-icon-copy:before {
		  content:"\f3e1";
	  }

.cdc-icon-couch:before {
		  content:"\f3e2";
	  }

.cdc-icon-cow:before {
		  content:"\f3e3";
	  }

.cdc-icon-credit-card:before {
		  content:"\f3e4";
	  }

.cdc-icon-database:before {
		  content:"\f3e5";
	  }

.cdc-icon-deaf:before {
		  content:"\f3e6";
	  }

.cdc-icon-desktop-alt:before {
		  content:"\f3e7";
	  }

.cdc-icon-desktop:before {
		  content:"\f3e8";
	  }

.cdc-icon-diagnoses:before {
		  content:"\f3e9";
	  }

.cdc-icon-disease:before {
		  content:"\f3ea";
	  }

.cdc-icon-dna:before {
		  content:"\f3eb";
	  }

.cdc-icon-dog:before {
		  content:"\f3ec";
	  }

.cdc-icon-dolly-flatbed-alt:before {
		  content:"\f3ed";
	  }

.cdc-icon-door-closed:before {
		  content:"\f3ee";
	  }

.cdc-icon-door-open:before {
		  content:"\f3ef";
	  }

.cdc-icon-download:before {
		  content:"\f3f0";
	  }

.cdc-icon-drumstick:before {
		  content:"\f3f1";
	  }

.cdc-icon-dumbbell:before {
		  content:"\f3f2";
	  }

.cdc-icon-dumpster:before {
		  content:"\f3f3";
	  }

.cdc-icon-ear:before {
		  content:"\f3f4";
	  }

.cdc-icon-envelope-open-text:before {
		  content:"\f3f5";
	  }

.cdc-icon-envelope:before {
		  content:"\f3f6";
	  }

.cdc-icon-exclamation-circle:before {
		  content:"\f3f7";
	  }

.cdc-icon-exclamation-square:before {
		  content:"\f3f8";
	  }

.cdc-icon-exclamation-triangle:before {
		  content:"\f3f9";
	  }

.cdc-icon-eye-dropper:before {
		  content:"\f3fa";
	  }

.cdc-icon-eye:before {
		  content:"\f3fb";
	  }

.cdc-icon-farm:before {
		  content:"\f3fc";
	  }

.cdc-icon-female:before {
		  content:"\f3fd";
	  }

.cdc-icon-file-alt:before {
		  content:"\f3fe";
	  }

.cdc-icon-file-archive:before {
		  content:"\f3ff";
	  }

.cdc-icon-file-audio:before {
		  content:"\f400";
	  }

.cdc-icon-file-certificate:before {
		  content:"\f401";
	  }

.cdc-icon-file-chart-line:before {
		  content:"\f402";
	  }

.cdc-icon-file-medical-alt:before {
		  content:"\f403";
	  }

.cdc-icon-file-medical:before {
		  content:"\f404";
	  }

.cdc-icon-file-prescription:before {
		  content:"\f405";
	  }

.cdc-icon-files-medical:before {
		  content:"\f406";
	  }

.cdc-icon-first-aid:before {
		  content:"\f407";
	  }

.cdc-icon-flag-alt:before {
		  content:"\f408";
	  }

.cdc-icon-flag:before {
		  content:"\f409";
	  }

.cdc-icon-flask:before {
		  content:"\f40a";
	  }

.cdc-icon-flower-daffodil:before {
		  content:"\f40b";
	  }

.cdc-icon-folder-plus:before {
		  content:"\f40c";
	  }

.cdc-icon-football-ball:before {
		  content:"\f40d";
	  }

.cdc-icon-forklift:before {
		  content:"\f40e";
	  }

.cdc-icon-frown:before {
		  content:"\f40f";
	  }

.cdc-icon-gas-pump:before {
		  content:"\f410";
	  }

.cdc-icon-globe-africa:before {
		  content:"\f411";
	  }

.cdc-icon-globe-americas:before {
		  content:"\f412";
	  }

.cdc-icon-globe-asia:before {
		  content:"\f413";
	  }

.cdc-icon-globe-europe:before {
		  content:"\f414";
	  }

.cdc-icon-globe:before {
		  content:"\f415";
	  }

.cdc-icon-graduation-cap:before {
		  content:"\f416";
	  }

.cdc-icon-grin:before {
		  content:"\f417";
	  }

.cdc-icon-h-square:before {
		  content:"\f418";
	  }

.cdc-icon-hand-holding-heart:before {
		  content:"\f419";
	  }

.cdc-icon-hand-holding-medical:before {
		  content:"\f41a";
	  }

.cdc-icon-hand-paper:before {
		  content:"\f41b";
	  }

.cdc-icon-hand-point-right:before {
		  content:"\f41c";
	  }

.cdc-icon-hand-sparkles:before {
		  content:"\f41d";
	  }

.cdc-icon-hands-helping:before {
		  content:"\f41e";
	  }

.cdc-icon-hands-wash:before {
		  content:"\f41f";
	  }

.cdc-icon-handshake-slash:before {
		  content:"\f420";
	  }

.cdc-icon-head-side-cough-slash:before {
		  content:"\f421";
	  }

.cdc-icon-head-side-cough:before {
		  content:"\f422";
	  }

.cdc-icon-head-side-mask:before {
		  content:"\f423";
	  }

.cdc-icon-head-side-medical:before {
		  content:"\f424";
	  }

.cdc-icon-headset:before {
		  content:"\f425";
	  }

.cdc-icon-heart-rate:before {
		  content:"\f426";
	  }

.cdc-icon-heartbeat:before {
		  content:"\f427";
	  }

.cdc-icon-hiking:before {
		  content:"\f428";
	  }

.cdc-icon-home-lg:before {
		  content:"\f429";
	  }

.cdc-icon-hospital-alt:before {
		  content:"\f42a";
	  }

.cdc-icon-hospital-symbol:before {
		  content:"\f42b";
	  }

.cdc-icon-hospital-user:before {
		  content:"\f42c";
	  }

.cdc-icon-hospital:before {
		  content:"\f42d";
	  }

.cdc-icon-hotel:before {
		  content:"\f42e";
	  }

.cdc-icon-house-leave:before {
		  content:"\f42f";
	  }

.cdc-icon-house-return:before {
		  content:"\f430";
	  }

.cdc-icon-house-user:before {
		  content:"\f431";
	  }

.cdc-icon-ice-cream:before {
		  content:"\f432";
	  }

.cdc-icon-id-badge:before {
		  content:"\f433";
	  }

.cdc-icon-id-card:before {
		  content:"\f434";
	  }

.cdc-icon-inbox:before {
		  content:"\f435";
	  }

.cdc-icon-info-circle:before {
		  content:"\f436";
	  }

.cdc-icon-info-square:before {
		  content:"\f437";
	  }

.cdc-icon-info:before {
		  content:"\f438";
	  }

.cdc-icon-inhaler:before {
		  content:"\f439";
	  }

.cdc-icon-key:before {
		  content:"\f43a";
	  }

.cdc-icon-keyboard:before {
		  content:"\f43b";
	  }

.cdc-icon-keynote:before {
		  content:"\f43c";
	  }

.cdc-icon-landmark-alt:before {
		  content:"\f43d";
	  }

.cdc-icon-landmark:before {
		  content:"\f43e";
	  }

.cdc-icon-laptop-house:before {
		  content:"\f43f";
	  }

.cdc-icon-laptop-medical:before {
		  content:"\f440";
	  }

.cdc-icon-laptop:before {
		  content:"\f441";
	  }

.cdc-icon-leaf-oak:before {
		  content:"\f442";
	  }

.cdc-icon-leaf:before {
		  content:"\f443";
	  }

.cdc-icon-lightbulb:before {
		  content:"\f444";
	  }

.cdc-icon-link:before {
		  content:"\f445";
	  }

.cdc-icon-lock-alt:before {
		  content:"\f446";
	  }

.cdc-icon-lock-open-alt:before {
		  content:"\f447";
	  }

.cdc-icon-luggage-cart:before {
		  content:"\f448";
	  }

.cdc-icon-lungs-virus:before {
		  content:"\f449";
	  }

.cdc-icon-lungs:before {
		  content:"\f44a";
	  }

.cdc-icon-mail-bulk:before {
		  content:"\f44b";
	  }

.cdc-icon-male:before {
		  content:"\f44c";
	  }

.cdc-icon-map-marker-alt-slash:before {
		  content:"\f44d";
	  }

.cdc-icon-map:before {
		  content:"\f44e";
	  }

.cdc-icon-medkit:before {
		  content:"\f44f";
	  }

.cdc-icon-microphone:before {
		  content:"\f450";
	  }

.cdc-icon-microscope:before {
		  content:"\f451";
	  }

.cdc-icon-mobile-alt:before {
		  content:"\f452";
	  }

.cdc-icon-mobile:before {
		  content:"\f453";
	  }

.cdc-icon-monitor-heart-rate:before {
		  content:"\f454";
	  }

.cdc-icon-moon:before {
		  content:"\f455";
	  }

.cdc-icon-mortar-pestle:before {
		  content:"\f456";
	  }

.cdc-icon-mouse-pointer:before {
		  content:"\f457";
	  }

.cdc-icon-network-wired:before {
		  content:"\f458";
	  }

.cdc-icon-newspaper:before {
		  content:"\f459";
	  }

.cdc-icon-notes-medical:before {
		  content:"\f45a";
	  }

.cdc-icon-passport:before {
		  content:"\f45b";
	  }

.cdc-icon-paw:before {
		  content:"\f45c";
	  }

.cdc-icon-people-arrows:before {
		  content:"\f45d";
	  }

.cdc-icon-person-sign:before {
		  content:"\f45e";
	  }

.cdc-icon-phone-office:before {
		  content:"\f45f";
	  }

.cdc-icon-pills:before {
		  content:"\f460";
	  }

.cdc-icon-place-of-worship:before {
		  content:"\f461";
	  }

.cdc-icon-plane-arrival:before {
		  content:"\f462";
	  }

.cdc-icon-plane-departure:before {
		  content:"\f463";
	  }

.cdc-icon-plane-slash:before {
		  content:"\f464";
	  }

.cdc-icon-plane:before {
		  content:"\f465";
	  }

.cdc-icon-play-circle:before {
		  content:"\f466";
	  }

.cdc-icon-play:before {
		  content:"\f467";
	  }

.cdc-icon-poll-people:before {
		  content:"\f468";
	  }

.cdc-icon-popcorn:before {
		  content:"\f469";
	  }

.cdc-icon-portrait:before {
		  content:"\f46a";
	  }

.cdc-icon-prescription-bottle-alt:before {
		  content:"\f46b";
	  }

.cdc-icon-prescription-bottle:before {
		  content:"\f46c";
	  }

.cdc-icon-prescription:before {
		  content:"\f46d";
	  }

.cdc-icon-presentation:before {
		  content:"\f46e";
	  }

.cdc-icon-procedures:before {
		  content:"\f46f";
	  }

.cdc-icon-pump-medical:before {
		  content:"\f470";
	  }

.cdc-icon-pump-soap:before {
		  content:"\f471";
	  }

.cdc-icon-pumpkin:before {
		  content:"\f472";
	  }

.cdc-icon-question-circle:before {
		  content:"\f473";
	  }

.cdc-icon-question-square:before {
		  content:"\f474";
	  }

.cdc-icon-question:before {
		  content:"\f475";
	  }

.cdc-icon-ribbon:before {
		  content:"\f476";
	  }

.cdc-icon-running:before {
		  content:"\f477";
	  }

.cdc-icon-school:before {
		  content:"\f478";
	  }

.cdc-icon-search:before {
		  content:"\f479";
	  }

.cdc-icon-share-alt:before {
		  content:"\f47a";
	  }

.cdc-icon-share:before {
		  content:"\f47b";
	  }

.cdc-icon-ship:before {
		  content:"\f47c";
	  }

.cdc-icon-shipping-fast:before {
		  content:"\f47d";
	  }

.cdc-icon-shopping-cart:before {
		  content:"\f47e";
	  }

.cdc-icon-shower:before {
		  content:"\f47f";
	  }

.cdc-icon-shuttle-van:before {
		  content:"\f480";
	  }

.cdc-icon-sign-language:before {
		  content:"\f481";
	  }

.cdc-icon-sink:before {
		  content:"\f482";
	  }

.cdc-icon-sitemap:before {
		  content:"\f483";
	  }

.cdc-icon-soap:before {
		  content:"\f484";
	  }

.cdc-icon-spray-can:before {
		  content:"\f485";
	  }

.cdc-icon-star:before {
		  content:"\f486";
	  }

.cdc-icon-stethoscope:before {
		  content:"\f487";
	  }

.cdc-icon-stomach:before {
		  content:"\f488";
	  }

.cdc-icon-stopwatch:before {
		  content:"\f489";
	  }

.cdc-icon-store-alt-slash:before {
		  content:"\f48a";
	  }

.cdc-icon-store-slash:before {
		  content:"\f48b";
	  }

.cdc-icon-stretcher:before {
		  content:"\f48c";
	  }

.cdc-icon-subway:before {
		  content:"\f48d";
	  }

.cdc-icon-suitcase:before {
		  content:"\f48e";
	  }

.cdc-icon-sun:before {
		  content:"\f48f";
	  }

.cdc-icon-swimmer:before {
		  content:"\f490";
	  }

.cdc-icon-swimming-pool:before {
		  content:"\f491";
	  }

.cdc-icon-sync-alt:before {
		  content:"\f492";
	  }

.cdc-icon-syringe:before {
		  content:"\f493";
	  }

.cdc-icon-table:before {
		  content:"\f494";
	  }

.cdc-icon-tablet-alt:before {
		  content:"\f495";
	  }

.cdc-icon-tablets:before {
		  content:"\f496";
	  }

.cdc-icon-taxi:before {
		  content:"\f497";
	  }

.cdc-icon-temperature-high:before {
		  content:"\f498";
	  }

.cdc-icon-temperature-low:before {
		  content:"\f499";
	  }

.cdc-icon-thumbs-down:before {
		  content:"\f49a";
	  }

.cdc-icon-thumbs-up:before {
		  content:"\f49b";
	  }

.cdc-icon-times-circle:before {
		  content:"\f49c";
	  }

.cdc-icon-times:before {
		  content:"\f49d";
	  }

.cdc-icon-tint-slash:before {
		  content:"\f49e";
	  }

.cdc-icon-tint:before {
		  content:"\f49f";
	  }

.cdc-icon-toolbox:before {
		  content:"\f4a0";
	  }

.cdc-icon-tools:before {
		  content:"\f4a1";
	  }

.cdc-icon-tooth:before {
		  content:"\f4a2";
	  }

.cdc-icon-train:before {
		  content:"\f4a3";
	  }

.cdc-icon-trash:before {
		  content:"\f4a4";
	  }

.cdc-icon-tshirt:before {
		  content:"\f4a5";
	  }

.cdc-icon-umbrella-beach:before {
		  content:"\f4a6";
	  }

.cdc-icon-universal-access:before {
		  content:"\f4a7";
	  }

.cdc-icon-user-chart:before {
		  content:"\f4a8";
	  }

.cdc-icon-user-clock:before {
		  content:"\f4a9";
	  }

.cdc-icon-user-cog:before {
		  content:"\f4aa";
	  }

.cdc-icon-user-friends:before {
		  content:"\f4ab";
	  }

.cdc-icon-user-graduate:before {
		  content:"\f4ac";
	  }

.cdc-icon-user-hard-hat:before {
		  content:"\f4ad";
	  }

.cdc-icon-user-injured:before {
		  content:"\f4ae";
	  }

.cdc-icon-user-md-chat:before {
		  content:"\f4af";
	  }

.cdc-icon-user-md:before {
		  content:"\f4b0";
	  }

.cdc-icon-user-nurse:before {
		  content:"\f4b1";
	  }

.cdc-icon-user:before {
		  content:"\f4b2";
	  }

.cdc-icon-users-class:before {
		  content:"\f4b3";
	  }

.cdc-icon-users-medical:before {
		  content:"\f4b4";
	  }

.cdc-icon-users-slash:before {
		  content:"\f4b5";
	  }

.cdc-icon-users:before {
		  content:"\f4b6";
	  }

.cdc-icon-venus-mars:before {
		  content:"\f4b7";
	  }

.cdc-icon-vial:before {
		  content:"\f4b8";
	  }

.cdc-icon-vials:before {
		  content:"\f4b9";
	  }

.cdc-icon-virus-slash:before {
		  content:"\f4ba";
	  }

.cdc-icon-virus:before {
		  content:"\f4bb";
	  }

.cdc-icon-viruses:before {
		  content:"\f4bc";
	  }

.cdc-icon-walking:before {
		  content:"\f4bd";
	  }

.cdc-icon-washer:before {
		  content:"\f4be";
	  }

.cdc-icon-water:before {
		  content:"\f4bf";
	  }

.cdc-icon-weight:before {
		  content:"\f4c0";
	  }

.cdc-icon-wheelchair:before {
		  content:"\f4c1";
	  }

.cdc-icon-window-close:before {
		  content:"\f4c2";
	  }

.cdc-icon-x-ray:before {
		  content:"\f4c3";
	  }

.cdc-icon-address-book-solid:before {
		  content:"\f4c4";
	  }

.cdc-icon-address-card-solid:before {
		  content:"\f4c5";
	  }

.cdc-icon-allergies-solid:before {
		  content:"\f4c6";
	  }

.cdc-icon-ambulance-solid:before {
		  content:"\f4c7";
	  }

.cdc-icon-american-sign-language-interpreting-solid:before {
		  content:"\f4c8";
	  }

.cdc-icon-analytics-solid:before {
		  content:"\f4c9";
	  }

.cdc-icon-apple-alt-solid:before {
		  content:"\f4ca";
	  }

.cdc-icon-archive-solid:before {
		  content:"\f4cb";
	  }

.cdc-icon-arrow-alt-circle-down-solid:before {
		  content:"\f4cc";
	  }

.cdc-icon-arrow-alt-circle-left-solid:before {
		  content:"\f4cd";
	  }

.cdc-icon-arrow-alt-circle-right-solid:before {
		  content:"\f4ce";
	  }

.cdc-icon-arrow-alt-circle-up-solid:before {
		  content:"\f4cf";
	  }

.cdc-icon-atlas-solid:before {
		  content:"\f4d0";
	  }

.cdc-icon-baby-carriage-solid:before {
		  content:"\f4d1";
	  }

.cdc-icon-baby-solid:before {
		  content:"\f4d2";
	  }

.cdc-icon-bacteria-solid:before {
		  content:"\f4d3";
	  }

.cdc-icon-bacterium-solid:before {
		  content:"\f4d4";
	  }

.cdc-icon-balance-scale-solid:before {
		  content:"\f4d5";
	  }

.cdc-icon-ballot-check-solid:before {
		  content:"\f4d6";
	  }

.cdc-icon-ballot-solid:before {
		  content:"\f4d7";
	  }

.cdc-icon-ban-solid:before {
		  content:"\f4d8";
	  }

.cdc-icon-band-aid-solid:before {
		  content:"\f4d9";
	  }

.cdc-icon-bed-solid:before {
		  content:"\f4da";
	  }

.cdc-icon-bicycle-solid:before {
		  content:"\f4db";
	  }

.cdc-icon-biking-solid:before {
		  content:"\f4dc";
	  }

.cdc-icon-blind-solid:before {
		  content:"\f4dd";
	  }

.cdc-icon-bolt-solid:before {
		  content:"\f4de";
	  }

.cdc-icon-book-alt-solid:before {
		  content:"\f4df";
	  }

.cdc-icon-book-medical-solid:before {
		  content:"\f4e0";
	  }

.cdc-icon-book-open-solid:before {
		  content:"\f4e1";
	  }

.cdc-icon-book-reader-solid:before {
		  content:"\f4e2";
	  }

.cdc-icon-book-solid:before {
		  content:"\f4e3";
	  }

.cdc-icon-book-user-solid:before {
		  content:"\f4e4";
	  }

.cdc-icon-books-medical-solid:before {
		  content:"\f4e5";
	  }

.cdc-icon-box-tissue-solid:before {
		  content:"\f4e6";
	  }

.cdc-icon-bread-loaf-solid:before {
		  content:"\f4e7";
	  }

.cdc-icon-briefcase-medical-solid:before {
		  content:"\f4e8";
	  }

.cdc-icon-briefcase-solid:before {
		  content:"\f4e9";
	  }

.cdc-icon-browser-solid:before {
		  content:"\f4ea";
	  }

.cdc-icon-building-solid:before {
		  content:"\f4eb";
	  }

.cdc-icon-bullseye-pointer-solid:before {
		  content:"\f4ec";
	  }

.cdc-icon-burger-soda-solid:before {
		  content:"\f4ed";
	  }

.cdc-icon-bus-solid:before {
		  content:"\f4ee";
	  }

.cdc-icon-calendar-alt-solid:before {
		  content:"\f4ef";
	  }

.cdc-icon-calendar-check-solid:before {
		  content:"\f4f0";
	  }

.cdc-icon-calendar-day-solid:before {
		  content:"\f4f1";
	  }

.cdc-icon-calendar-edit-solid:before {
		  content:"\f4f2";
	  }

.cdc-icon-calendar-exclamation-solid:before {
		  content:"\f4f3";
	  }

.cdc-icon-calendar-plus-solid:before {
		  content:"\f4f4";
	  }

.cdc-icon-calendar-solid:before {
		  content:"\f4f5";
	  }

.cdc-icon-calendar-star-solid:before {
		  content:"\f4f6";
	  }

.cdc-icon-capsules-solid:before {
		  content:"\f4f7";
	  }

.cdc-icon-car-bus-solid:before {
		  content:"\f4f8";
	  }

.cdc-icon-car-side-solid:before {
		  content:"\f4f9";
	  }

.cdc-icon-carrot-solid:before {
		  content:"\f4fa";
	  }

.cdc-icon-chalkboard-teacher-solid:before {
		  content:"\f4fb";
	  }

.cdc-icon-chart-area-solid:before {
		  content:"\f4fc";
	  }

.cdc-icon-chart-bar-solid:before {
		  content:"\f4fd";
	  }

.cdc-icon-chart-line-solid:before {
		  content:"\f4fe";
	  }

.cdc-icon-chart-pie-solid:before {
		  content:"\f4ff";
	  }

.cdc-icon-check-circle-solid:before {
		  content:"\f500";
	  }

.cdc-icon-check-solid:before {
		  content:"\f501";
	  }

.cdc-icon-check-square-solid:before {
		  content:"\f502";
	  }

.cdc-icon-cheese-swiss-solid:before {
		  content:"\f503";
	  }

.cdc-icon-cheeseburger-solid:before {
		  content:"\f504";
	  }

.cdc-icon-chevron-circle-down-solid:before {
		  content:"\f505";
	  }

.cdc-icon-chevron-circle-left-solid:before {
		  content:"\f506";
	  }

.cdc-icon-chevron-circle-right-solid:before {
		  content:"\f507";
	  }

.cdc-icon-chevron-circle-up-solid:before {
		  content:"\f508";
	  }

.cdc-icon-chevron-double-right-solid:before {
		  content:"\f509";
	  }

.cdc-icon-chevron-right-solid:before {
		  content:"\f50a";
	  }

.cdc-icon-child-solid:before {
		  content:"\f50b";
	  }

.cdc-icon-city-solid:before {
		  content:"\f50c";
	  }

.cdc-icon-clinic-medical-solid:before {
		  content:"\f50d";
	  }

.cdc-icon-clipboard-check-solid:before {
		  content:"\f50e";
	  }

.cdc-icon-clipboard-list-check-solid:before {
		  content:"\f50f";
	  }

.cdc-icon-clipboard-list-solid:before {
		  content:"\f510";
	  }

.cdc-icon-clipboard-prescription-solid:before {
		  content:"\f511";
	  }

.cdc-icon-clipboard-solid:before {
		  content:"\f512";
	  }

.cdc-icon-clipboard-user-solid:before {
		  content:"\f513";
	  }

.cdc-icon-cloud-solid:before {
		  content:"\f514";
	  }

.cdc-icon-clouds-sun-solid:before {
		  content:"\f515";
	  }

.cdc-icon-cocktail-solid:before {
		  content:"\f516";
	  }

.cdc-icon-code-solid:before {
		  content:"\f517";
	  }

.cdc-icon-coffee-togo-solid:before {
		  content:"\f518";
	  }

.cdc-icon-cog-solid:before {
		  content:"\f519";
	  }

.cdc-icon-cogs-solid:before {
		  content:"\f51a";
	  }

.cdc-icon-comment-alt-lines-solid:before {
		  content:"\f51b";
	  }

.cdc-icon-comment-alt-medical-solid:before {
		  content:"\f51c";
	  }

.cdc-icon-comment-alt-solid:before {
		  content:"\f51d";
	  }

.cdc-icon-comment-lines-solid:before {
		  content:"\f51e";
	  }

.cdc-icon-comment-medical-solid:before {
		  content:"\f51f";
	  }

.cdc-icon-comment-solid:before {
		  content:"\f520";
	  }

.cdc-icon-comments-alt-solid:before {
		  content:"\f521";
	  }

.cdc-icon-comments-solid:before {
		  content:"\f522";
	  }

.cdc-icon-conveyor-belt-solid:before {
		  content:"\f523";
	  }

.cdc-icon-copy-solid:before {
		  content:"\f524";
	  }

.cdc-icon-couch-solid:before {
		  content:"\f525";
	  }

.cdc-icon-cow-solid:before {
		  content:"\f526";
	  }

.cdc-icon-credit-card-solid:before {
		  content:"\f527";
	  }

.cdc-icon-database-solid:before {
		  content:"\f528";
	  }

.cdc-icon-deaf-solid:before {
		  content:"\f529";
	  }

.cdc-icon-desktop-alt-solid:before {
		  content:"\f52a";
	  }

.cdc-icon-desktop-solid:before {
		  content:"\f52b";
	  }

.cdc-icon-diagnoses-solid:before {
		  content:"\f52c";
	  }

.cdc-icon-disease-solid:before {
		  content:"\f52d";
	  }

.cdc-icon-dna-solid:before {
		  content:"\f52e";
	  }

.cdc-icon-dog-solid:before {
		  content:"\f52f";
	  }

.cdc-icon-dolly-flatbed-alt-solid:before {
		  content:"\f530";
	  }

.cdc-icon-door-closed-solid:before {
		  content:"\f531";
	  }

.cdc-icon-door-open-solid:before {
		  content:"\f532";
	  }

.cdc-icon-download-solid:before {
		  content:"\f533";
	  }

.cdc-icon-drumstick-solid:before {
		  content:"\f534";
	  }

.cdc-icon-dumbbell-solid:before {
		  content:"\f535";
	  }

.cdc-icon-dumpster-solid:before {
		  content:"\f536";
	  }

.cdc-icon-ear-solid:before {
		  content:"\f537";
	  }

.cdc-icon-envelope-open-text-solid:before {
		  content:"\f538";
	  }

.cdc-icon-envelope-solid:before {
		  content:"\f539";
	  }

.cdc-icon-exclamation-circle-solid:before {
		  content:"\f53a";
	  }

.cdc-icon-exclamation-square-solid:before {
		  content:"\f53b";
	  }

.cdc-icon-exclamation-triangle-solid:before {
		  content:"\f53c";
	  }

.cdc-icon-eye-dropper-solid:before {
		  content:"\f53d";
	  }

.cdc-icon-eye-solid:before {
		  content:"\f53e";
	  }

.cdc-icon-farm-solid:before {
		  content:"\f53f";
	  }

.cdc-icon-female-solid:before {
		  content:"\f540";
	  }

.cdc-icon-file-alt-solid:before {
		  content:"\f541";
	  }

.cdc-icon-file-archive-solid:before {
		  content:"\f542";
	  }

.cdc-icon-file-audio-solid:before {
		  content:"\f543";
	  }

.cdc-icon-file-certificate-solid:before {
		  content:"\f544";
	  }

.cdc-icon-file-chart-line-solid:before {
		  content:"\f545";
	  }

.cdc-icon-file-medical-alt-solid:before {
		  content:"\f546";
	  }

.cdc-icon-file-medical-solid:before {
		  content:"\f547";
	  }

.cdc-icon-file-prescription-solid:before {
		  content:"\f548";
	  }

.cdc-icon-files-medical-solid:before {
		  content:"\f549";
	  }

.cdc-icon-first-aid-solid:before {
		  content:"\f54a";
	  }

.cdc-icon-flag-alt-solid:before {
		  content:"\f54b";
	  }

.cdc-icon-flag-solid:before {
		  content:"\f54c";
	  }

.cdc-icon-flask-solid:before {
		  content:"\f54d";
	  }

.cdc-icon-flower-daffodil-solid:before {
		  content:"\f54e";
	  }

.cdc-icon-folder-plus-solid:before {
		  content:"\f54f";
	  }

.cdc-icon-football-ball-solid:before {
		  content:"\f550";
	  }

.cdc-icon-forklift-solid:before {
		  content:"\f551";
	  }

.cdc-icon-frown-solid:before {
		  content:"\f552";
	  }

.cdc-icon-gas-pump-solid:before {
		  content:"\f553";
	  }

.cdc-icon-globe-africa-solid:before {
		  content:"\f554";
	  }

.cdc-icon-globe-americas-solid:before {
		  content:"\f555";
	  }

.cdc-icon-globe-asia-solid:before {
		  content:"\f556";
	  }

.cdc-icon-globe-europe-solid:before {
		  content:"\f557";
	  }

.cdc-icon-globe-solid:before {
		  content:"\f558";
	  }

.cdc-icon-graduation-cap-solid:before {
		  content:"\f559";
	  }

.cdc-icon-grin-solid:before {
		  content:"\f55a";
	  }

.cdc-icon-h-square-solid:before {
		  content:"\f55b";
	  }

.cdc-icon-hand-holding-heart-solid:before {
		  content:"\f55c";
	  }

.cdc-icon-hand-holding-medical-solid:before {
		  content:"\f55d";
	  }

.cdc-icon-hand-paper-solid:before {
		  content:"\f55e";
	  }

.cdc-icon-hand-point-right-solid:before {
		  content:"\f55f";
	  }

.cdc-icon-hand-sparkles-solid:before {
		  content:"\f560";
	  }

.cdc-icon-hands-helping-solid:before {
		  content:"\f561";
	  }

.cdc-icon-hands-wash-solid:before {
		  content:"\f562";
	  }

.cdc-icon-handshake-slash-solid:before {
		  content:"\f563";
	  }

.cdc-icon-head-side-cough-slash-solid:before {
		  content:"\f564";
	  }

.cdc-icon-head-side-cough-solid:before {
		  content:"\f565";
	  }

.cdc-icon-head-side-mask-solid:before {
		  content:"\f566";
	  }

.cdc-icon-head-side-medical-solid:before {
		  content:"\f567";
	  }

.cdc-icon-headset-solid:before {
		  content:"\f568";
	  }

.cdc-icon-heart-rate-solid:before {
		  content:"\f569";
	  }

.cdc-icon-heartbeat-solid:before {
		  content:"\f56a";
	  }

.cdc-icon-hiking-solid:before {
		  content:"\f56b";
	  }

.cdc-icon-home-lg-solid:before {
		  content:"\f56c";
	  }

.cdc-icon-hospital-alt-solid:before {
		  content:"\f56d";
	  }

.cdc-icon-hospital-solid:before {
		  content:"\f56e";
	  }

.cdc-icon-hospital-symbol-solid:before {
		  content:"\f56f";
	  }

.cdc-icon-hospital-user-solid:before {
		  content:"\f570";
	  }

.cdc-icon-hotel-solid:before {
		  content:"\f571";
	  }

.cdc-icon-house-leave-solid:before {
		  content:"\f572";
	  }

.cdc-icon-house-return-solid:before {
		  content:"\f573";
	  }

.cdc-icon-house-user-solid:before {
		  content:"\f574";
	  }

.cdc-icon-ice-cream-solid:before {
		  content:"\f575";
	  }

.cdc-icon-id-badge-solid:before {
		  content:"\f576";
	  }

.cdc-icon-id-card-solid:before {
		  content:"\f577";
	  }

.cdc-icon-inbox-solid:before {
		  content:"\f578";
	  }

.cdc-icon-info-circle-solid:before {
		  content:"\f579";
	  }

.cdc-icon-info-solid:before {
		  content:"\f57a";
	  }

.cdc-icon-info-square-solid:before {
		  content:"\f57b";
	  }

.cdc-icon-inhaler-solid:before {
		  content:"\f57c";
	  }

.cdc-icon-key-solid:before {
		  content:"\f57d";
	  }

.cdc-icon-keyboard-solid:before {
		  content:"\f57e";
	  }

.cdc-icon-keynote-solid:before {
		  content:"\f57f";
	  }

.cdc-icon-landmark-alt-solid:before {
		  content:"\f580";
	  }

.cdc-icon-landmark-solid:before {
		  content:"\f581";
	  }

.cdc-icon-laptop-house-solid:before {
		  content:"\f582";
	  }

.cdc-icon-laptop-medical-solid:before {
		  content:"\f583";
	  }

.cdc-icon-laptop-solid:before {
		  content:"\f584";
	  }

.cdc-icon-leaf-oak-solid:before {
		  content:"\f585";
	  }

.cdc-icon-leaf-solid:before {
		  content:"\f586";
	  }

.cdc-icon-lightbulb-solid:before {
		  content:"\f587";
	  }

.cdc-icon-link-solid:before {
		  content:"\f588";
	  }

.cdc-icon-lock-alt-solid:before {
		  content:"\f589";
	  }

.cdc-icon-lock-open-alt-solid:before {
		  content:"\f58a";
	  }

.cdc-icon-luggage-cart-solid:before {
		  content:"\f58b";
	  }

.cdc-icon-lungs-solid:before {
		  content:"\f58c";
	  }

.cdc-icon-lungs-virus-solid:before {
		  content:"\f58d";
	  }

.cdc-icon-mail-bulk-solid:before {
		  content:"\f58e";
	  }

.cdc-icon-male-solid:before {
		  content:"\f58f";
	  }

.cdc-icon-map-marker-alt-slash-solid:before {
		  content:"\f590";
	  }

.cdc-icon-map-solid:before {
		  content:"\f591";
	  }

.cdc-icon-medkit-solid:before {
		  content:"\f592";
	  }

.cdc-icon-microphone-solid:before {
		  content:"\f593";
	  }

.cdc-icon-microscope-solid:before {
		  content:"\f594";
	  }

.cdc-icon-mobile-alt-solid:before {
		  content:"\f595";
	  }

.cdc-icon-mobile-solid:before {
		  content:"\f596";
	  }

.cdc-icon-monitor-heart-rate-solid:before {
		  content:"\f597";
	  }

.cdc-icon-moon-solid:before {
		  content:"\f598";
	  }

.cdc-icon-mortar-pestle-solid:before {
		  content:"\f599";
	  }

.cdc-icon-mouse-pointer-solid:before {
		  content:"\f59a";
	  }

.cdc-icon-network-wired-solid:before {
		  content:"\f59b";
	  }

.cdc-icon-newspaper-solid:before {
		  content:"\f59c";
	  }

.cdc-icon-notes-medical-solid:before {
		  content:"\f59d";
	  }

.cdc-icon-passport-solid:before {
		  content:"\f59e";
	  }

.cdc-icon-paw-solid:before {
		  content:"\f59f";
	  }

.cdc-icon-people-arrows-solid:before {
		  content:"\f5a0";
	  }

.cdc-icon-person-sign-solid:before {
		  content:"\f5a1";
	  }

.cdc-icon-phone-office-solid:before {
		  content:"\f5a2";
	  }

.cdc-icon-pills-solid:before {
		  content:"\f5a3";
	  }

.cdc-icon-place-of-worship-solid:before {
		  content:"\f5a4";
	  }

.cdc-icon-plane-arrival-solid:before {
		  content:"\f5a5";
	  }

.cdc-icon-plane-departure-solid:before {
		  content:"\f5a6";
	  }

.cdc-icon-plane-slash-solid:before {
		  content:"\f5a7";
	  }

.cdc-icon-plane-solid:before {
		  content:"\f5a8";
	  }

.cdc-icon-play-circle-solid:before {
		  content:"\f5a9";
	  }

.cdc-icon-play-solid:before {
		  content:"\f5aa";
	  }

.cdc-icon-poll-people-solid:before {
		  content:"\f5ab";
	  }

.cdc-icon-popcorn-solid:before {
		  content:"\f5ac";
	  }

.cdc-icon-portrait-solid:before {
		  content:"\f5ad";
	  }

.cdc-icon-prescription-bottle-alt-solid:before {
		  content:"\f5ae";
	  }

.cdc-icon-prescription-bottle-solid:before {
		  content:"\f5af";
	  }

.cdc-icon-prescription-solid:before {
		  content:"\f5b0";
	  }

.cdc-icon-presentation-solid:before {
		  content:"\f5b1";
	  }

.cdc-icon-procedures-solid:before {
		  content:"\f5b2";
	  }

.cdc-icon-pump-medical-solid:before {
		  content:"\f5b3";
	  }

.cdc-icon-pump-soap-solid:before {
		  content:"\f5b4";
	  }

.cdc-icon-pumpkin-solid:before {
		  content:"\f5b5";
	  }

.cdc-icon-question-circle-solid:before {
		  content:"\f5b6";
	  }

.cdc-icon-question-solid:before {
		  content:"\f5b7";
	  }

.cdc-icon-question-square-solid:before {
		  content:"\f5b8";
	  }

.cdc-icon-ribbon-solid:before {
		  content:"\f5b9";
	  }

.cdc-icon-running-solid:before {
		  content:"\f5ba";
	  }

.cdc-icon-school-solid:before {
		  content:"\f5bb";
	  }

.cdc-icon-search-solid:before {
		  content:"\f5bc";
	  }

.cdc-icon-share-alt-solid:before {
		  content:"\f5bd";
	  }

.cdc-icon-share-solid:before {
		  content:"\f5be";
	  }

.cdc-icon-ship-solid:before {
		  content:"\f5bf";
	  }

.cdc-icon-shipping-fast-solid:before {
		  content:"\f5c0";
	  }

.cdc-icon-shopping-cart-solid:before {
		  content:"\f5c1";
	  }

.cdc-icon-shower-solid:before {
		  content:"\f5c2";
	  }

.cdc-icon-shuttle-van-solid:before {
		  content:"\f5c3";
	  }

.cdc-icon-sign-language-solid:before {
		  content:"\f5c4";
	  }

.cdc-icon-sink-solid:before {
		  content:"\f5c5";
	  }

.cdc-icon-sitemap-solid:before {
		  content:"\f5c6";
	  }

.cdc-icon-soap-solid:before {
		  content:"\f5c7";
	  }

.cdc-icon-spray-can-solid:before {
		  content:"\f5c8";
	  }

.cdc-icon-star-solid:before {
		  content:"\f5c9";
	  }

.cdc-icon-stethoscope-solid:before {
		  content:"\f5ca";
	  }

.cdc-icon-stomach-solid:before {
		  content:"\f5cb";
	  }

.cdc-icon-stopwatch-solid:before {
		  content:"\f5cc";
	  }

.cdc-icon-store-alt-slash-solid:before {
		  content:"\f5cd";
	  }

.cdc-icon-store-slash-solid:before {
		  content:"\f5ce";
	  }

.cdc-icon-stretcher-solid:before {
		  content:"\f5cf";
	  }

.cdc-icon-subway-solid:before {
		  content:"\f5d0";
	  }

.cdc-icon-suitcase-solid:before {
		  content:"\f5d1";
	  }

.cdc-icon-sun-solid:before {
		  content:"\f5d2";
	  }

.cdc-icon-swimmer-solid:before {
		  content:"\f5d3";
	  }

.cdc-icon-swimming-pool-solid:before {
		  content:"\f5d4";
	  }

.cdc-icon-sync-alt-solid:before {
		  content:"\f5d5";
	  }

.cdc-icon-syringe-solid:before {
		  content:"\f5d6";
	  }

.cdc-icon-table-solid:before {
		  content:"\f5d7";
	  }

.cdc-icon-tablet-alt-solid:before {
		  content:"\f5d8";
	  }

.cdc-icon-tablets-solid:before {
		  content:"\f5d9";
	  }

.cdc-icon-taxi-solid:before {
		  content:"\f5da";
	  }

.cdc-icon-temperature-high-solid:before {
		  content:"\f5db";
	  }

.cdc-icon-temperature-low-solid:before {
		  content:"\f5dc";
	  }

.cdc-icon-thumbs-down-solid:before {
		  content:"\f5dd";
	  }

.cdc-icon-thumbs-up-solid:before {
		  content:"\f5de";
	  }

.cdc-icon-times-circle-solid:before {
		  content:"\f5df";
	  }

.cdc-icon-times-solid:before {
		  content:"\f5e0";
	  }

.cdc-icon-tint-slash-solid:before {
		  content:"\f5e1";
	  }

.cdc-icon-tint-solid:before {
		  content:"\f5e2";
	  }

.cdc-icon-toolbox-solid:before {
		  content:"\f5e3";
	  }

.cdc-icon-tools-solid:before {
		  content:"\f5e4";
	  }

.cdc-icon-tooth-solid:before {
		  content:"\f5e5";
	  }

.cdc-icon-train-solid:before {
		  content:"\f5e6";
	  }

.cdc-icon-trash-solid:before {
		  content:"\f5e7";
	  }

.cdc-icon-tshirt-solid:before {
		  content:"\f5e8";
	  }

.cdc-icon-umbrella-beach-solid:before {
		  content:"\f5e9";
	  }

.cdc-icon-universal-access-solid:before {
		  content:"\f5ea";
	  }

.cdc-icon-user-chart-solid:before {
		  content:"\f5eb";
	  }

.cdc-icon-user-clock-solid:before {
		  content:"\f5ec";
	  }

.cdc-icon-user-cog-solid:before {
		  content:"\f5ed";
	  }

.cdc-icon-user-friends-solid:before {
		  content:"\f5ee";
	  }

.cdc-icon-user-graduate-solid:before {
		  content:"\f5ef";
	  }

.cdc-icon-user-hard-hat-solid:before {
		  content:"\f5f0";
	  }

.cdc-icon-user-injured-solid:before {
		  content:"\f5f1";
	  }

.cdc-icon-user-md-chat-solid:before {
		  content:"\f5f2";
	  }

.cdc-icon-user-md-solid:before {
		  content:"\f5f3";
	  }

.cdc-icon-user-nurse-solid:before {
		  content:"\f5f4";
	  }

.cdc-icon-user-solid:before {
		  content:"\f5f5";
	  }

.cdc-icon-users-class-solid:before {
		  content:"\f5f6";
	  }

.cdc-icon-users-medical-solid:before {
		  content:"\f5f7";
	  }

.cdc-icon-users-slash-solid:before {
		  content:"\f5f8";
	  }

.cdc-icon-users-solid:before {
		  content:"\f5f9";
	  }

.cdc-icon-venus-mars-solid:before {
		  content:"\f5fa";
	  }

.cdc-icon-vial-solid:before {
		  content:"\f5fb";
	  }

.cdc-icon-vials-solid:before {
		  content:"\f5fc";
	  }

.cdc-icon-virus-slash-solid:before {
		  content:"\f5fd";
	  }

.cdc-icon-virus-solid:before {
		  content:"\f5fe";
	  }

.cdc-icon-viruses-solid:before {
		  content:"\f5ff";
	  }

.cdc-icon-walking-solid:before {
		  content:"\f600";
	  }

.cdc-icon-washer-solid:before {
		  content:"\f601";
	  }

.cdc-icon-water-solid:before {
		  content:"\f602";
	  }

.cdc-icon-weight-solid:before {
		  content:"\f603";
	  }

.cdc-icon-wheelchair-solid:before {
		  content:"\f604";
	  }

.cdc-icon-window-close-solid:before {
		  content:"\f605";
	  }

.cdc-icon-x-ray-solid:before {
		  content:"\f606";
	  }

/* stylelint-enable */
