/* modals */

.modal {
  .modal-title {
    margin-top: 0;
    font-size: 1.375rem;
    line-height: inherit;
    font-family: $opensans;
  }

  button.close {
    background-color: $black;
    padding: 0.5rem;
    font-size: inherit;
    line-height: 0;
    position: absolute;
    top: 1.7rem;
    right: 1.7rem;
    z-index: 999;
    border: 1px solid $white;

    svg {
      fill: $white;
    }

    .fi {
      color: $white;
      text-shadow: none;
    }
  }

  .modal-body {
    svg {
      fill: $cdcblue;
    }
  }

  .extlink-url-notice {
    color: $graydark;
    font-size: 10pt;
    position: absolute;
    left: 18px;
  }

  .modal-footer {
    a {
      color: inherit;
    }
  }
}

.modal-full {
  .modal-header .modal-content {
    border-radius: 0 !important;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 80%;
  max-height: 80%;

  .modal-body {
    button.close {
      background-color: $black;
      padding: 0.5rem;
      font-size: inherit;
      line-height: 0;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    + .modal-body {
      background-color: $black;
      opacity: 0.7;
      color: $white;
      position: absolute;
      bottom: 58px;
      width: 100%;
    }
  }

  .modal-footer {
    .modal-buttons {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
    }
  }
}

.modal-fullscreen {
  min-width: 100%;
  margin: 0;

  .modal-header .modal-content {
    border-radius: 0 !important;
  }

  .modal-body {
    button.close {
      background-color: $black;
      padding: 0.5rem;
      font-size: inherit;
      line-height: 0;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    + .modal-body {
      background-color: $black;
      opacity: 0.7;
      color: $white;
      position: absolute;
      bottom: 58px;
      width: 100%;
    }
  }

  .modal-footer {
    .modal-buttons {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
    }
  }
}

@media (max-width: 767.98px) {
  .modal-full {
    top: 0;
    left: 0;
    transform: none !important;
    max-width: 100%;
    max-height: 100%;
  }
}
