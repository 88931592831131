/* topic-media */

.card-topic-media {
  .card-header {
    background-color: $white;
  }

  .card-body {
    > ul {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: 0;

      li {
        display: inline-block;
        padding-left: 1rem;
        margin-bottom: 0;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
