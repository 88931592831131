/* cards */

//https://websupport.cdc.gov/browse/WCMSRD-6247
.card-deck {
  display: flex;
  // flex-direction: column;

  > a {
    margin-bottom: 15px;

    .card {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

a.card {
  text-decoration: none;

  // https://websupport.cdc.gov/browse/WCMSRD-6247
  .btn {
    pointer-events: none;
  }
}

.card.card-multi {
  .card {
    a {
      height: auto;
    }
  }
}

.card {
  border-color: $graylight;

  // this "fixes" problem in IE where an anchor wraps the card-img-top and breaks the layout
  .card > a {
    height: 100%;
  }

  &.card-multi .card {
    height: inherit;
  }

  .card {
    background-color: transparent !important;
    border: none;
    height: auto;

    //https://websupport.cdc.gov/browse/WCMSRD-5946
    // .card-body {
    // 	padding-left: 0;
    // 	padding-right: 0;
    // 	padding-bottom: 9px;
    // }
  }

  &.tb-rc {
    //transparent-border, rounded corners
    border-radius: 0.25rem;
    border-top: 0 solid transparent;
    border-right: 0 solid transparent !important;
    border-bottom: 0 solid transparent !important;
    border-left: 0 solid transparent !important;

    .card-body {
      border-radius: 0.25rem;
    }
  }

  &.tb-rc-with-header {
    //transparent-border, rounded corners
    .card-body {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
  }

  &.border-trans {
    border-top: 0 solid transparent;
    border-right: 0 solid transparent !important;
    border-bottom: 0 solid transparent !important;
    border-left: 0 solid transparent !important;

    .card-body {
      border-radius: 0.25rem;
    }
  }

  // don't allow rounded corners on top image if the card has a top border
  &.bt-1,
  &.bt-2,
  &.bt-3,
  &.bt-4,
  &.bt-5 {
    .card-img-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  //img {height:100%;}

  // headings in cards should not be Merriweather
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: $opensans;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;

    a {
      text-decoration: none;
    }
  }

  &.card-zoom {
    transform: scale(1);
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-transform 0.15s ease-in-out;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }

  &.card-flush {
    .card {
      border: 0;
    }
  }

  //https://websupport.cdc.gov/browse/WCMSRD-5618
  //https://websupport.cdc.gov/browse/WCMSRD-6105
  .card-footer {
    // min-height: 30px;  // pete doesn't like this fix

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      &.btn {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.fd-row {
    .card-img-top {
      border-top-right-radius: 0;
      border-bottom-left-radius: calc(0.25rem - 1px);
    }
  }

  .card-header,
  .card-body,
  .card-footer {
    padding: 1rem;
  }

  .card-body {
    // :not(:first-child) {
    // 	margin-top: 1rem;
    // }

    //https://websupport.cdc.gov/browse/WCMSRD-6214
    // .card-subtitle, p {
    // 	margin-top: 1rem;
    // }

    > p:last-child {
      margin-bottom: 0;
    }

    .card-subtitle {
      font-size: 0.8rem;
    }

    > ul,
    > ol {
      padding-left: 25px;
    }

    > ul.lsp-out,
    > ol.lsp-out,
    > ul.block-list {
      padding-left: 0;
    }

    ul:only-child {
      margin-bottom: 0;
    }
  }

  .card-header {
    padding-top: 0.5rem; // (pt-2)
    padding-bottom: 0.5rem; // (pb-2)
    + .card-img-top {
      border-radius: 0;
    }

    a {
      svg {
        float: left;

        + span {
          display: block;
          overflow: hidden;
        }
      }
    }

    // "fixes" issue where the collapse link appears too low
    a:not(.collapse-link) svg {
      margin-top: 8px;
    }
  }

  .card-img-none {
    width: 100%;
    border-radius: 0;
  }

  .card-img-left {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }

  .card-img-right {
    width: 100%;
    border-top-right-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px);
  }

  // Fix problem in IEX, where card images stretch
  .card-img-top,
  .card-img-bottom {
    flex-shrink: 0;
  }

  .card-img-top {
    // on container
    &.img-zoom {
      &:hover {
        img {
          opacity: 0.5;
          transform: scale(1.2, 1.2);
          transition: all 0.3s ease 0s;
        }
      }

      &:active,
      &:focus {
        img {
          opacity: 0.5;
          transform: scale(0.99, 0.99);
          transition: all 0.3s ease 0s;
        }
      }
    }

    // on img
    img {
      &.img-zoom {
        &:hover {
          opacity: 0.5;
          transform: scale(1.2, 1.2);
          transition: all 0.3s ease 0s;
        }

        &:active,
        &:focus {
          opacity: 0.5;
          transform: scale(0.99, 0.99);
          transition: all 0.3s ease 0s;
        }
      }
    }

    &.card-img-orig {
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .card-icon {
    padding: 1rem;
  }

  .image-with-caption {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

a.card-btn {
  color: inherit;
  text-decoration: none;

  // styles typically applied to buttons from BS
  div,
  span {
    .btn {
      text-transform: none;
      overflow: visible;
      margin: 0;
      font-family: inherit;
      cursor: auto;
      // this needs to only happen to cards which are buttons
      // pointer-events: none;	// prevent hover effect
    }
  }
}

// IE11 'display:flex' bug fix - image stretching
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  //old implementation of equal height cards
  .d-flex .flex-fill {
    .card-body {
      //height: auto;
      //.card {
      //height: 100%;
      //.card-body {
      //height: 100% !important;
      //}
      //}
    }
  }

  //new implementation of equal height cards
  .card.card-multi {
    .card {
      /*height: 100%;*/
      flex: none !important;
    }

    .card-body {
      height: auto;
      flex: none !important;
    }
  }

  .card {
    /* IE fix for card image stretching outside of it's container */
    // img {height:100%;}
    // .card-img-top {height:100%;}
    // Keep an eye on this, fixes IMG stretching in additional cases
    //a img {height:auto!important;}
    //a .card-img-top {height:auto!important;}
    .card-img-container {
      height: 100%;
    } // leaving this in until WCMS implements change

    //height:auto fixes WCMSRD-7275
    .card-body {
      height: 100%;
    }

    // .card-header {
    // 	+ .card-img-top {height:100%;}
    // }

    // anchor tags with content attempts to match container height with height 100%
    a {
      min-height: 1px;
    }

    .card-header {
      flex: 0 0 auto !important;
      //margin-bottom: -1px;
    }

    //.card-footer { margin-top: -1px; }
    .card-footer svg {
      margin: 5px;
    }

    &.nav-btn {
      .card-body {
        /*@media only screen and ( max-width: 1200px ) and ( min-width: 992px ) {
					.col {
						width: 100%;
					}
				}*/
        .col {
          //min-width: 60%;
        }

        .card-icon {
          //min-width: 30%;

          /*@media only screen and ( max-width: 768px ) {
						.icon {
							width: 100% !important;
							height: auto !important;
						}
					}*/
        }

        .card-title {
          //word-wrap: normal;
          //display: block !important;
        }
      } //end card-body
    } //end nav-btn
  } //end IE card
} //end IE card breakpoint
