/* timeline */

.cdc-timeline {
  svg {
    width: 25px;
    height: 25px;
  }

  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  ul.list-bullet li {
    margin: 15px;
  }

  &::before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 3px;
    background-color: #f5f5f5; // don't change this!
    left: 50%;
    margin-left: -1.5px;
  }

  > li {
    margin-bottom: 20px;
    position: relative;
    width: 50%;
    float: left;
    clear: left;

    &::before,
    &::after {
      content: ' ';
      display: table;
    }

    &::after {
      clear: both;
    }

    &:nth-child(2) {
      margin-top: 60px;
    }

    .timeline-panel {
      width: 95%;
      float: left;
      border: 1px solid #d4d4d4;
      position: relative;
      // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
      border-radius: 0.25rem;

      &::before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: ' ';
      }

      &::after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: ' ';
      }
    }

    .timeline-heading {
      img {
        border-top-left-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
      }
    }

    .timeline-badge {
      color: #fff;
      width: 24px;
      height: 24px;
      line-height: 50px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: 20px;
      right: -12px;
      z-index: 100;

      a {
        color: #005eaa !important;

        &:hover {
          color: blue !important;
        }
      }
    }

    &.timeline-inverted {
      float: right;
      clear: right;
      margin-top: 30px;
      margin-bottom: 30px;

      > .timeline-panel {
        float: right;

        &::before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }

        &::after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }

      > .timeline-badge {
        left: -12px;
      }
    }

    .timeline-title {
      margin: 0.75rem 1rem -20px 1rem;
      color: inherit;
      font-family: 'Open Sans', sans-serif;
    }

    .timeline-body {
      > p,
      > ul {
        padding: 0.75rem 1rem;
        margin-bottom: 0;
      }

      > p + p {
        margin-top: 5px;
      }
    }

    .timeline-footer {
      padding: 1rem;
      background-color: #f4f4f4;

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .cdc-timeline {
    &::before {
      left: 10px;
    }

    > li {
      margin-bottom: 20px;
      position: relative;
      width: 100%;
      float: left;
      clear: left;

      > .timeline-badge {
        left: -2px;
        margin-left: 0;
      }

      > .timeline-panel {
        width: calc(100% - 40px);
        float: right;

        &::before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }

        &::after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }

      &.timeline-inverted {
        float: left;
        clear: left;
        margin-top: 30px;
        margin-bottom: 30px;

        > .timeline-badge {
          left: -2px;
        }
      }
    }
  }
}
