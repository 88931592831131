/* accordion */

.accordion {
  .card {
    border: 0;
  }

  .card-title {
    margin-bottom: 0;
  }

  .card-header {
    cursor: pointer;
    position: relative;
    background-color: $graylightest;

    //&:first-child {
    //border-top: 1px solid rgba(0, 0, 0, 0.125);
    //}

    &.collapsed {
      background-color: $graylightest;
      // margin-bottom: .25rem;
    }

    &:hover {
      background-color: $graylight;
    }

    .card-title {
      padding-right: 50px;
      font-weight: bold;
      white-space: normal;
      text-align: left;

      &.btn.btn-link:focus {
        text-decoration: underline;
        outline: none;

        @include focusBoxShadow();
        transform: scale(1, 1);
      }
    }
  }

  &.heading-right {
    .card-title {
      position: absolute;
      right: 50px;
    }
  }

  &.accordion-white {
    .card-header {
      background-color: $white;

      &:hover {
        background-color: $graylightest;
      }
    }
  }

  &.indicator-plus {
    .card-header {
      &::after {
        content: ' ';
        background-image: url('/TemplatePackage/contrib/icons/materialdesignicons/minus.svg');
        background-size: 18px 18px;
        height: 18px;
        width: 18px;
        float: right;
        margin-top: 4px;
        transition: transform 0.25s linear;
        transform: rotate(180deg);
        position: absolute;
        right: 1rem;
        top: 25%;
      }

      &.collapsed {
        &::after {
          content: ' ';
          background-image: url(/TemplatePackage/contrib/icons/materialdesignicons/plus.svg);
          background-size: 18px 18px;
          height: 18px;
          width: 18px;
          float: right;
          margin-top: 4px;
          transition: transform 0.25s linear;
          transform: rotate(90deg);
          position: absolute;
          right: 1rem;
          top: 25%;
        }
      }
    }
  }

  &.indicator-plus-before {
    &.round-indicator {
      .card-header {
        &::before {
          // content: url(/TemplatePackage/contrib/icons/font-awesome/minus6.svg);
          content: ' ';
          background-image: url(/TemplatePackage/contrib/icons/font-awesome/minus6.svg);
          background-size: 18px 18px;
          height: 18px;
          width: 18px;
          margin-right: 10px;
          margin-top: 4px;
        }

        &.collapsed {
          &::before {
            // content: url(/TemplatePackage/contrib/icons/font-awesome/plus6.svg);
            content: ' ';
            background-image: url(/TemplatePackage/contrib/icons/font-awesome/plus6.svg);
            background-size: 18px 18px;
            height: 18px;
            width: 18px;
            margin-top: 4px;
          }
        }
      }
    }

    .card-header {
      &::before {
        font-family: 'FontAwesome';
        content: '\f068';
        float: left;
      }

      &.collapsed {
        &::before {
          content: '\f067';
        }
      }
    }
  }

  &.indicator-chevron {
    .card-header {
      &::after {
        // content: url(/TemplatePackage/contrib/icons/font-awesome/.svg);
        content: ' ';
        background-image: url(/TemplatePackage/contrib/icons/font-awesome/chevron-down2.svg);
        background-size: 18px 18px;
        height: 18px;
        width: 18px;
        float: right;
        margin-top: 4px;
      }

      &.collapsed {
        &::after {
          //content: url(/TemplatePackage/contrib/icons/font-awesome/chevron-up2.svg);
          content: ' ';
          background-image: url(/TemplatePackage/contrib/icons/font-awesome/chevron-up2.svg);
          background-size: 18px 18px;
          height: 18px;
          width: 18px;
          margin-top: 4px;
        }
      }
    }
  }

  &.background-none {
    [class^='card'] {
      background: transparent;
    }
  }

  &.border-0 {
    .card {
      border: 0;
    }
  }

  .collapse.show {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    &:last-child {
      border-bottom: none;
    }
  }
}

// .hide-me, used on collapsible things; when they are expanded, hide them
.btn.hide-me[aria-expanded='true'] {
  display: none;
}
