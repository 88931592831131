/* opacity */

.op-100 {
  opacity: 1;
}

.op-90 {
  opacity: 0.9;
}

.op-80 {
  opacity: 0.8;
}

.op-75 {
  opacity: 0.75;
}

.op-50 {
  opacity: 0.5;
}

.op-25 {
  opacity: 0.25;
}

.op-0 {
  opacity: 0;
}
