/* dropdowns */

.dropdown {
  &.dropdown-languages {
    margin-top: -5px;
    margin-bottom: 33px;

    .dropdown-item {
      padding: 0.5rem 1.5rem;
    }
  }
}
